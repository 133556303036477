import React from 'react';

const Kub = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.79408 8.39067L15.001 5.59067M8.79408 8.39067V15.1907M8.79408 8.39067L3.00098 5.59067M15.001 5.59067V12.7907L8.79408 15.1907M15.001 5.59067L8.79408 3.19067L3.00098 5.59067M8.79408 15.1907L3.00098 12.7907V5.59067" stroke="#4F5E69"/>
    </svg>
  );
};

export default Kub;
