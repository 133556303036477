import React from 'react';

const VolumeIcon = () => {
  return (
    <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.41384 6.83213L12.2145 4.01409M6.41384 6.83213V13.6759M6.41384 6.83213L0.999878 4.01409M12.2145 4.01409V11.2605L6.41384 13.6759M12.2145 4.01409L6.41384 1.59863L0.999878 4.01409M6.41384 13.6759L0.999878 11.2605V4.01409" stroke="#3D4D59" strokeWidth="1.294"/>
    </svg>
  );
};

export default React.memo(VolumeIcon);
