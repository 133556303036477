import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import TopBar from '../components/top-bar';
import Footer from '../components/footer';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setCity } from '../store/action';
import Loader from '../components/Loader';

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      maxWidth: '270px',
      display: 'flex',
      justifyContent: 'center',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',

      '@media (max-width: 600px)': {
        flexDirection: 'column',
        textAlign: 'center',
      },
    },
    container: {
      height: 'calc(100vh - 333px)',
      position: 'relative',

      '@media (max-width: 1024px) and (min-width: 601px)': {
        height: 'calc(100vh - 219px)',
      },

      '@media (max-width: 600px)': {
        height: 'calc(100vh - 466px)',
      },
    },
    text: {
      fontWeight: 600,
      fontSize: '33px',
      lineHeight: '40px',
      color: '#000000',
      cursor: 'pointer',

      '&:hover': {
        color: '#FD6120',
      },

      '@media (max-width: 600px)': {
       padding: '28px 0',
        fontSize: '30px',
        lineHeight: '20px',
      },
    },
  }),
);

const CitySelection = ({incomplete}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch()
  const { lang, loading } = useSelector((state) => state.reducer);
  const text = lang === 'en' ? 'Odessa' : lang === 'ua' ? 'Одеса' : 'Одесса'
  const city = [{title: text, value: 'odessa' }]

  const handleClick = (value) => {
    dispatch(setCity(value))
    history.push(`/${lang}/${value}`)
  }

  return (
    loading ? (
      <Loader/>
    ) : (
      <div>
        <div className='container'>
          <TopBar incomplete />
          <div className={classes.container}>
            <div className={classes.wrapper}>
              {city.map((item, index) => (
                <span key={`lang${index}`} onClick={() => handleClick(item.value)} className={classes.text}>{item.title}</span>
              ))}
            </div>
          </div>
        </div>
        <Footer incomplete/>
      </div>
    )
  );
};

export default CitySelection;
