import React from 'react';
import TopBar from '../components/top-bar';
import Footer from '../components/footer';
import Avtopark from '../components/avtopark';
import Muver from '../components/muver';
import BlockMinOrder from '../components/block-min-zakaz';
import UpacovkaBlock from '../components/upacovka-block';
import BlockLink from '../components/block-link/block-link';
import BlockVideo from '../components/block-video';
import MessengerBlock from '../components/messenger-block';
import OurClients from '../components/ our-clients';
import { useSelector } from 'react-redux';
import Loader from '../components/Loader';
import { createStyles, makeStyles } from '@material-ui/core';
import Gruzoperevoski from '../components/headers/gruzoperevoski';
import { Helmet } from 'react-helmet';
import BreadCrumbs from '../components/bread-crumbs';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: '458px',
      marginLeft: '-30px',
      marginRight: '-30px',
      '@media (max-width: 1129px) and (min-width: 601px)': {
        height: '248px',
        marginLeft: '-15px',
        marginRight: '-15px',
      },

      '@media (max-width: 600px)': {
        boxShadow: '0px 14px 32px rgba(199, 203, 206, 0.7)',
        marginLeft: '0px',
        marginRight: '0px',
        borderRadius: '8px',
        height: '456px',
      },
    },
  }),
);

const Gruzoperevozki = () => {
  const classes = useStyles()
  const { loading, lang } = useSelector((state) => state.reducer);
  return (
    loading ? (
      <Loader/>
    ) : (
    <div>
      {lang === 'ru' && (
        <Helmet
          title="Грузоперевозки в Одессе - Мой Перевозчик ™"
          meta={[
            {"name": "description", "content": "Компания Мой Перевозчик предоставляет все виды мувинговых услуг в Одессе: грузоперевозки, переезды, хранение, вывоз мусора, услуги муверов и упаковку. Наша визия - мувинговая компания №1 в Мире."},
            {"name": "keywords", "content": "грузоперевозки одесса, грузовые перевозки в одессе, перевозка груза по одессе, доставка груза одесса, грузовое такси в одессе, перевозки по одессе, мой перевозчик одесса, перевозчик одесса"}
          ]}
        >
          <meta property="og:image" content="https://www.mycarrier.company/wordpres/wp-content/uploads/2022/07/openg.jpg" />
        </Helmet>
      )}
      {lang === 'ua' && (
        <Helmet
          title="Вантажні перевезення в Одесі - Мій Перевізник ™"
          meta={[
            {"name": "description", "content": "Швидкі вантажні перевезення в Одесі з компанією «Мій Перевізник» – приїхали, побачили, перевезли. Мувінгова компанія №1 в Україні."},
            {"name": "keywords", "content": "вантажні перевезення одеса, вантажоперевезення одеса, перевезення вантажу одеса, доставка вантажу одеса, вантажне таксі одеса, перевезення одеса, мій перевізник одеса, перевізник одеса"}
          ]}
        >
          <meta property="og:image" content="https://www.mycarrier.company/wordpres/wp-content/uploads/2022/07/openg.jpg" />
        </Helmet>
      )}
      {lang === 'en' && (
        <Helmet
          title="Freight transportation in Odesa - My Carrier ™"
          meta={[
            {"name": "description", "content": "Quick freight transportation in Odesa with the My Carrier company - arrived, saw, transported. Moving company number one in Ukraine."},
          ]}
        >
          <meta property="og:image" content="https://www.mycarrier.company/wordpres/wp-content/uploads/2022/07/openg.jpg" />
        </Helmet>
      )}

      <div className='container'>
        <TopBar/>
        <BreadCrumbs />
        <div className={classes.container}>
          <Gruzoperevoski />
        </div>
        <Avtopark/>
        <Muver/>
        <BlockMinOrder/>
        <UpacovkaBlock id='gruzoperevozki'/>
        <OurClients />
        <BlockVideo/>
        <MessengerBlock/>
      </div>
      <Footer/>
    </div>
    )
  );
};

export default React.memo(Gruzoperevozki);
