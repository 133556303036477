import React from 'react';
import ContainerIcon from '../../assets/ContainerIcon';
import './style.scss'
import ExportImg from '../../assets/ExportImg';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { pagePath } from '../../rout';

const BlockLink = () => {
  const { homeLink, lang, city } = useSelector((state) => state.reducer);

  return (
    <div className='block-links'>

      <div className="block-links-item">
        <div className='block-link_img'>
          <ContainerIcon/>
        </div>

        <div className='block-links-content'>
          <div className='block-links_title'>{homeLink?.acf.title_hranenie}</div>
          <div className='block-links_text'>{homeLink?.acf.text_hranenie}</div>
          <Link className='block-links_button' to={`/${lang}/${city}${pagePath.hranenie}`}>
            {homeLink?.acf.button}
          </Link>
        </div>
      </div>

      <div className="block-links-item">
        <div className='block-links_img'>
          <ExportImg/>
        </div>

        <div className='block-links-content'>
          <div className='block-links_title'>{homeLink?.acf.title_vuvoz}</div>
          <div className='block-links_text'>{homeLink?.acf.text_vuvoz}</div>
          <Link className='block-links_button' to={`/${lang}/${city}${pagePath.vuvoz}`}>{homeLink?.acf.button}</Link>
        </div>
      </div>
    </div>
  );
};

export default React.memo(BlockLink);
