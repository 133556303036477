import React from 'react';

const ShirinaVusota = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.00098 14L8.66585 14C9.95551 14 11.001 12.9545 11.001 11.6649L11.001 8.33512C11.001 7.04547 9.95551 6 8.66585 6L7.00098 6" stroke="#4F5E69"/>
      <path d="M7.00098 14L5.3361 14C4.04645 14 3.00098 12.9545 3.00098 11.6649L3.00098 8.33512C3.00098 7.04547 4.04645 6 5.3361 6L7.00098 6" stroke="#4F5E69"/>
      <path d="M14.8147 7.24989L15.446 7.85318L15.9907 7.47609L14.446 6L13.0116 7.48492L13.574 7.84435L14.0995 7.30041L14.0859 12.6504L13.5847 12.1315L13.001 12.5045L14.4456 14L16.001 12.5137L15.4357 12.1223L14.8282 12.7028L14.8147 7.24989Z" fill="#4F5E69"/>
      <path d="M9.75109 2.81372L9.14779 3.44505L9.52489 3.9897L11.001 2.44502L9.51606 1.01064L9.15662 1.57304L9.70056 2.09848L4.35059 2.08495L4.86951 1.58368L4.49647 1L3.00098 2.4446L4.48731 4L4.87867 3.43475L4.29815 2.82725L9.75109 2.81372Z" fill="#4F5E69"/>
    </svg>
  );
};

export default ShirinaVusota;
