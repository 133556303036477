import React from 'react';

const PlusIcon = () => {
  return (
    <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_3042_15860)">
        <path d="M21.9323 17L14.2505 17" stroke="#FD6120" strokeWidth="3.84089" strokeLinecap="round"/>
        <path d="M18.0908 20.841L18.0908 13.1592" stroke="#FD6120" strokeWidth="3.84089" strokeLinecap="round"/>
      </g>
      <defs>
        <filter id="filter0_d_3042_15860" x="0.039238" y="0.586713" width="36.1041" height="36.1044" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="1.63878"/>
          <feGaussianBlur stdDeviation="6.14542"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.992157 0 0 0 0 0.380392 0 0 0 0 0.12549 0 0 0 0.65 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3042_15860"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3042_15860" result="shape"/>
        </filter>
      </defs>
    </svg>
  );
};

export default React.memo(PlusIcon);
