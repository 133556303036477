import React from 'react';
import './style.scss'
import { useSelector } from 'react-redux';
import { TgIcon, VbIcon, WsIcon } from '../../assets';
import { conversion } from '../../helper';

const MessengerBlock = () => {
  const { messenger } = useSelector((state) => state.reducer);

  return (
    <div id='messager' className='messenger'>
      <h2 className='messenger-title'>{messenger?.title.rendered}</h2>

      <div className='messenger-wrapper'>
        <div className='messenger-items'>
          <a href={messenger?.acf.viberlink} onClick={() => conversion('url')} className='messenger-item'>
            <VbIcon />
            <span>{messenger?.acf.viber}</span>
          </a>
          <a href={messenger?.acf.telegramlink} onClick={() => conversion('url')} className='messenger-item'>
            <TgIcon/>
            <span>{messenger?.acf.telegram}</span>
          </a>
          <a href={messenger?.acf.whatsapplink} onClick={() => conversion('url')} className='messenger-item'>
            <WsIcon/>
            <span>{messenger?.acf.whatsapp}</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default MessengerBlock;
