export const LANG = 'LANG'
export const CONVENIENT_STORAGE = 'CONVENIENT_STORAGE'
export const UPDATE_ALL = 'UPDATE_ALL'
export const OPEN_MENU = 'OPEN_MENU'
export const GET_MOVER = 'GET_MOVER'
export const MIN_ORDER = 'MIN_ORDER'
export const HOME_LINK = 'HOME_LINK'
export const REMOVAL_GARBAGE = 'REMOVAL_GARBAGE'
export const PAYMENT = 'PAYMENT'
export const STORAGE = 'STORAGE'
export const LOCATION = 'LOCATION'
export const CAR_PARK = 'CAR_PARK'
export const PACKAGING = 'PACKAGING'
export const LOADING = 'LOADING'
export const HEADER = 'HEADER'
export const MESSENGER = 'MESSENGER'
export const FOOTER = 'FOOTER'
export const SLIDER = 'SLIDER'
export const CITY = 'CITY'
export const OUR_CLIENTS = 'OUR_CLIENTS'
export const BREAD_CRUMBS = 'BREAD_CRUMBS'
