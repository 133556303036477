import {
  BREAD_CRUMBS,
  CAR_PARK, CITY,
  CONVENIENT_STORAGE, FOOTER, GET_MOVER, HEADER, HOME_LINK,
  LANG, LOADING, LOCATION, MESSENGER, MIN_ORDER,
  OPEN_MENU, OUR_CLIENTS, PACKAGING, PAYMENT, REMOVAL_GARBAGE, SLIDER, STORAGE
} from './types';

import axios from 'axios';

export const updateAll = () => {
  return (dispatch) => {
    dispatch(getConvenientStorage())
  };
}

export const getHeader = () => {
  return async (dispatch, getState) => {
    dispatch({type: LOADING, payload: true})
    const state = getState();
    const {
      reducer: { lang },
    } = state;

    const header = await axios
      .get('https://www.mycarrier.company/wordpres/wp-json/wp/v2/header')
      .catch(err => console.log(err));

    const filterData = header.data.filter((item) => item.slug === lang)
    dispatch({type: HEADER, payload: filterData[0]})
  };
};

export const getConvenientStorage = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const {
      reducer: { lang },
    } = state;

    const convenientStorage = await axios
      .get(`https://www.mycarrier.company/wordpres/wp-json/wp/v2/udobnoe-hran-block`)
      .catch(err => console.log(err));

    const filterData = convenientStorage.data.filter((item) => item.slug === lang)

    const sortData = filterData.map((item) => {
      const i = item.acf
      return {
        udobnoe_hrahehie_title: i.udobnoe_hrahehie_title,
        zaprecheno: i.zaprecheno,
        help_title: i.help_title,
        help_text: i.help_text,
        help_button: i.help_button,
        osvobodite: i.osvobodite,
        osvobodite_text: i.osvobodite_text,
        hranenie: [
          {
            text: i.hranenie_1_text,
            icon: i.hranenie_1_img,
          },
          {
            text: i.hranenie_2_text,
            icon: i.hranenie_2_img,
          },
          {
            text: i.hranenie_3_text,
            icon: i.hranenie_3_img,
          },
          {
            text: i.hranenie_4_text,
            icon: i.hranenie_4_img,
          },
          {
            text: i.hranenie_5_text,
            icon: i.hranenie_5_img,
          },
          {
            text: i.hranenie_6_text,
            icon: i.hranenie_6_img,
          },
          {
            text: i.hranenie_7_text,
            icon: i.hranenie_7_img,
          },
          {
            text: i.hranenie_8_text,
            icon: i.hranenie_8_img,
          },
        ],
        zaprechenoItems:[
          {
            text: i.zaprecheno_1_text,
            icon: i.zaprecheno_1_img,
          },
          {
            text: i.zaprecheno_2_text,
            icon: i.zaprecheno_2_img,
          },
          {
            text: i.zaprecheno_3_text,
            icon: i.zaprecheno_3_img,
          },
          {
            text: i.zaprecheno_4_text,
            icon: i.zaprecheno_4_img,
          },
        ],
        dop: [
          {
            text: i.text_1,
            title: i.title_1,
            icon: i.icon_1,
          },
          {
            text: i.text_2,
            title: i.title_2,
            icon: i.icon_2,
          },
          {
            text: i.text_3,
            title: i.title_3,
            icon: i.icon_3,
          },
          {
            text: i.text_4,
            title: i.title_4,
            icon: i.icon_4,
          },
          {
            text: i.text_5,
            title: i.title_5,
            icon: i.icon_5,
          },
        ]
      }
    })
    dispatch({type: CONVENIENT_STORAGE, payload: sortData[0]})
  };
};

export const setCity = (city) => {
  return {
    type: CITY,
    payload: city
  }
}

export const setLang = (lang) => {
  return {
    type: LANG,
    payload: lang
  }
}

export const setIsOpenMenu = (isOpen) => {
  return {
    type: OPEN_MENU,
    payload: isOpen
  }
}

export const getMover = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const {
      reducer: { lang },
    } = state;

    const mover = await axios
      .get(`https://www.mycarrier.company/wordpres/wp-json/wp/v2/muver`)
      .catch(err => console.log(err));

    const filterData = mover.data.filter((item) => item.slug === lang)
    dispatch({type: GET_MOVER, payload: filterData[0]})
  };
};

export const getMinOrder = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const {
      reducer: { lang },
    } = state;

    const minOrder = await axios
      .get(`https://www.mycarrier.company/wordpres/wp-json/wp/v2/min_zakaz`)
      .catch(err => console.log(err));

    const filterData = minOrder.data.filter((item) => item.slug === lang)
    dispatch({type: MIN_ORDER, payload: filterData[0]})
  };
};

export const getHomeLink = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const {
      reducer: { lang },
    } = state;

    const homeLink = await axios
      .get(`https://www.mycarrier.company/wordpres/wp-json/wp/v2/link_home`)
      .catch(err => console.log(err));

    const filterData = homeLink.data.filter((item) => item.slug === lang)
    dispatch({type: HOME_LINK, payload: filterData[0]})
  };
};

export const getRemovalOfGarbage = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const {
      reducer: { lang },
    } = state;

    const removalOfGarbage = await axios
      .get(`https://www.mycarrier.company/wordpres/wp-json/wp/v2/vuvoz_musora`)
      .catch(err => console.log(err));

    const filterData = removalOfGarbage.data.filter((item) => item.slug === lang)

    const sortData = filterData.map((item) => {
      const i = item.acf
      return {
        titleContainer: i.containers_title,
        titleSamoswal: i.samosval_title,
        button: i.button,
        razmer: i.razmer,
        razmer_text: i.razmer_text,
        shirina: i.shirina,
        dlina: i.dlina,
        vusota: i.vusota,
        rec: [
          {
            text: i.podacha,
            icon: i.podacha_icon
          },
          {
            text: i.pomosh,
            icon: i.pomosh_icon
          },
          {
            text: i.vuvoz,
            icon: i.vuvoz_icon
          },
        ],
        cards: [
          {
            title: i.standart,
            desc: i.standart_desc,
            img: i.standart_img,
            price: i.standart_price,
            value: i.standart_obem,
            ves: i.standart_ves,
            text: i.standart_text,
            shirina: i.standart_shirina,
          },
          {
            title: i.extra,
            desc: i.extra_desc,
            img: i.extra_img,
            price: i.extra_price,
            value: i.extra_obem,
            ves: i.extra_ves,
            text: i.extra_text,
            shirina: i.extra_shirina,
          },
          {
            title: i.maxi,
            desc: i.maxi_desc,
            img: i.maxi_img,
            price: i.maxi_price,
            value: i.maxi_obem,
            ves: i.maxi_ves,
            text: i.maxi_text,
            shirina: i.maxi_shirina,
          },
        ],
        samoswal: [
          {
            title: i.standart_s,
            desc: i.standart_s_desc,
            img: i.standart_s_img,
            price: i.standart_s_price,
            value: i.standart_s_obem,
            ves: i.standart_s_ves,
            text: i.standart_s_text,
            shirina: i.standart_s_shirina,
          },
          {
            title: i.extra_s,
            desc: i.extra_s_desc,
            img: i.extra_s_img,
            price: i.extra_s_price,
            value: i.extra_s_obem,
            ves: i.extra_s_ves,
            text: i.extra_s_text,
            shirina: i.extra_s_shirina,
          },
          {
            title: i.maxi_s,
            desc: i.maxi_s_desc,
            img: i.maxi_s_img,
            price: i.maxi_s_price,
            value: i.maxi_s_obem,
            ves: i.maxi_s_ves,
            text: i.maxi_s_text,
            shirina: i.maxi_s_shirina,
          },
        ]
      }
    })

    dispatch({type: REMOVAL_GARBAGE, payload: sortData[0]})
  };
};

export const getPayment = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const {
      reducer: { lang },
    } = state;

    const payment = await axios
      .get(`https://www.mycarrier.company/wordpres/wp-json/wp/v2/payment`)
      .catch(err => console.log(err));

    const filterData = payment.data.filter((item) => item.slug === lang)
    dispatch({type: PAYMENT, payload: filterData[0]})
  };
};

export const getStorage = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const {
      reducer: { lang },
    } = state;

    const storage = await axios
      .get(`https://www.mycarrier.company/wordpres/wp-json/wp/v2/containersstorage`)
      .catch(err => console.log(err));

    const filterData = storage.data.filter((item) => item.slug === lang)
    const countData = storage.data.filter((item) => item.slug === 'ru')?.[0]?.acf
    
    const sortData = filterData.map((item) => {
      const i = item.acf
      return {
        title: item.title.rendered,
        cards: [
          {
            title: i.container_five_fut,
            img: i.container_5_fut_img,
            price: i.container_five_fut_price,
            button: i.button,
            dlina: i.dlina,
            dlinaValue: i.dlina_5_fut_value,
            obem: i.obem,
            obemValue: i.obem_5_fut_value,
            ploshad: i.ploshad,
            ploshadValue: i.ploshad_5_fut_value,
            shirina: i.shirina,
            shirinaValue: i.shirina_5_fut_value,
            vusota: i.vusota,
            vusotaValue: i.vusota_5_fut_value,
            count: countData?.count_5 || 0,
            totalCount: countData?.total_count_5 || 0,
            rest: i.rest
          },
          {
            title: i.container_ten_fut,
            img: i.container_10_fut_img,
            price: i.container_ten_fut_price,
            button: i.button,
            dlina: i.dlina,
            dlinaValue: i.dlina_10_fut_value,
            obem: i.obem,
            obemValue: i.obem_10_fut_value,
            ploshad: i.ploshad,
            ploshadValue: i.ploshad_10_fut_value,
            shirina: i.shirina,
            shirinaValue: i.shirina_10_fut_value,
            vusota: i.vusota,
            vusotaValue: i.vusota_10_fut_value,
            count: countData?.count_10 || 0,
            totalCount: countData?.total_count_10 || 0,
            rest: i.rest
          },
          {
            title: i.container_20_fut,
            img: i.container_20_fut_img,
            price: i.container_20_fut_price,
            button: i.button,
            dlina: i.dlina,
            dlinaValue: i.dlina_20_fut_value,
            obem: i.obem,
            obemValue: i.obem_20_fut_value,
            ploshad: i.ploshad,
            ploshadValue: i.ploshad_20_fut_value,
            shirina: i.shirina,
            shirinaValue: i.shirina_20_fut_value,
            vusota: i.vusota,
            vusotaValue: i.vusota_20_fut_value,
            count: countData?.count_20 || 0,
            totalCount: countData?.total_count_20 || 0,
            rest: i.rest
          },
          {
            title: i.container_40_fut,
            img: i.container_40_fut_img,
            price: i.container_40_fut_price,
            button: i.button,
            dlina: i.dlina,
            dlinaValue: i.dlina_40_fut_value,
            obem: i.obem,
            obemValue: i.obem_40_fut_value,
            ploshad: i.ploshad,
            ploshadValue: i.ploshad_40_fut_value,
            shirina: i.shirina,
            shirinaValue: i.shirina_40_fut_value,
            vusota: i.vusota,
            vusotaValue: i.vusota_40_fut_value,
            count: countData?.count_40 || 0,
            totalCount: countData?.total_count_40 || 0,
            rest: i.rest
          },
        ],
      }
    })

    dispatch({type: STORAGE, payload: sortData[0]})
  };
};

export const getLocation = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const {
      reducer: { lang },
    } = state;

    const location = await axios
      .get(`https://www.mycarrier.company/wordpres/wp-json/wp/v2/our_location`)
      .catch(err => console.log(err));

    const filterData = location.data.filter((item) => item.slug === lang)
    dispatch({type: LOCATION, payload: filterData[0]})
  };
};

export const getCarPark = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const {
      reducer: { lang },
    } = state;

    const carPark = await axios
      .get(`https://www.mycarrier.company/wordpres/wp-json/wp/v2/avtopark?per_page=100`)
      .catch(err => console.log(err));

    const filterData = carPark.data.filter((item) => item.slug.includes(lang)).sort((a, b) => (a.acf.price - b.acf.price) ? 1 : -1)

    dispatch({type: CAR_PARK, payload: { data: filterData, title: filterData[0]?.acf.car_park, }})
  };
};

export const getPackaging = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const {
      reducer: { lang },
    } = state;

    const packaging = await axios
      .get(`https://www.mycarrier.company/wordpres/wp-json/wp/v2/upacovka-block?per_page=100`)
      .catch(err => console.log(err));

    const filterData = packaging.data.filter((item) => item.slug.includes(lang))

    dispatch({type: PACKAGING, payload: { data: filterData, title: filterData[0]?.acf.title}})
  };
};

export const getMessenger = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const {
      reducer: { lang },
    } = state;

    const messenger = await axios
      .get(`https://www.mycarrier.company/wordpres/wp-json/wp/v2/messenger-block`)
      .catch(err => console.log(err));

    const filterData = messenger.data.filter((item) => item.slug.includes(lang))

    dispatch({type: MESSENGER, payload: filterData[0]})
  };
};

export const getFooter = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const {
      reducer: { lang },
    } = state;

    const footer = await axios
      .get(`https://www.mycarrier.company/wordpres/wp-json/wp/v2/footer`)
      .catch(err => console.log(err));

    const filterData = footer.data.filter((item) => item.slug.includes(lang))

    dispatch({type: FOOTER, payload: filterData[0]})
  };
};

export const getSlider = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const {
      reducer: { lang },
    } = state;

    const slider = await axios
      .get(`https://www.mycarrier.company/wordpres/wp-json/wp/v2/slider?per_page=100`)
      .catch(err => console.log(err));

    const filterData = slider.data.filter((item) => item.slug.includes(lang))
    const gruzoperevoski = filterData?.filter((item) => {
      if (item?.title?.rendered?.includes('Грузоперевозки')) {
        return item
      }
    })
    const pereezd = filterData?.filter((item) => {
      if (item?.title?.rendered?.includes('Переезд')) {
        return item
      }
    })

    const hranenie = filterData?.filter((item) => {
      if (item?.title?.rendered?.includes('Хранение')) {
        return item
      }
    })
    const vuvoz = filterData?.filter((item) => {
      if (item?.title?.rendered?.includes('Вывоз мусора')) {
        return item
      }
    })

    const muvers = filterData?.filter((item) => {
      if (item?.title?.rendered?.includes('Муверы')) {
        return item
      }
    })

    const upacovka = filterData?.filter((item) => {
      if (item?.title?.rendered?.includes('Упаковка для переезда')) {
        return item
      }
    })

    const oplata = filterData?.filter((item) => {
      if (item?.title?.rendered?.includes('Оплата заказа') ) {
        return item
      }
    })

    dispatch({type: SLIDER, payload: {
        gruzoperevoski: gruzoperevoski,
        pereezd: pereezd,
        hranenie: hranenie,
        vuvoz: vuvoz,
        muvers: muvers,
        upacovka: upacovka,
        oplata: oplata,
      }})

    dispatch({type: LOADING, payload: false})
  };
};

export const getOurClients = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const {
      reducer: { lang },
    } = state;

    const ourClients = await axios
      .get(`https://www.mycarrier.company/wordpres/wp-json/wp/v2/clients`)
      .catch(err => console.log(err));

    const filterData = ourClients.data.filter((item) => item.slug.includes(lang))

    dispatch({type: OUR_CLIENTS, payload: filterData[0]})
  };
};

export const getBreabCrumbs = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const {
      reducer: { lang },
    } = state;

    const breabCrumbs = await axios
      .get(`https://www.mycarrier.company/wordpres/wp-json/wp/v2/hlebnue_kroshki`)
      .catch(err => console.log(err));

    const filterData = breabCrumbs.data.filter((item) => item.slug.includes(lang))

    dispatch({type: BREAD_CRUMBS, payload: filterData[0]})
  };
};


