import React from 'react';

const ArrowLeftGrey = () => {
  return (
    <svg width="29" height="16" viewBox="0 0 29 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.88274 7.29289C3.49221 7.68342 3.49221 8.31658 3.88274 8.70711L10.2467 15.0711C10.6372 15.4616 11.2704 15.4616 11.6609 15.0711C12.0514 14.6805 12.0514 14.0474 11.6609 13.6569L6.00406 8L11.6609 2.34315C12.0514 1.95262 12.0514 1.31946 11.6609 0.928932C11.2704 0.538408 10.6372 0.538408 10.2467 0.928932L3.88274 7.29289ZM24.5898 9C25.1421 9 25.5898 8.55228 25.5898 8C25.5898 7.44772 25.1421 7 24.5898 7V9ZM4.58984 9H24.5898V7H4.58984V9Z" fill="#BEC5D0"/>
    </svg>
  );
};

export default React.memo(ArrowLeftGrey);
