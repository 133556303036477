import React from 'react';

const LocationIcon = () => {
  return (
    <svg width="51" height="74" viewBox="0 0 51 74" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M25.166 71.4998C33.509 71.4998 40.2723 69.2205 40.2723 66.4089C40.2723 64.2654 36.3416 62.4313 30.7769 61.6807H19.555C13.9903 62.4313 10.0596 64.2654 10.0596 66.4089C10.0596 69.2205 16.8229 71.4998 25.166 71.4998Z" stroke="#FD6120" strokeWidth="3.67453"/>
      <path d="M28.4298 62.6575C26.6446 64.5946 23.6574 64.5981 21.861 62.6619C17.5473 58.0125 12.5013 51.8697 8.54604 45.4621C4.5548 38.9963 1.83726 32.5182 1.83726 27.1339C1.83726 18.7632 4.75384 12.6028 9.04395 8.52958C13.3572 4.43446 19.1771 2.33726 25.1521 2.33726C31.1249 2.33726 36.8785 4.43273 41.1279 8.52116C45.3569 12.59 48.2198 18.7516 48.2198 27.1339C48.2198 32.5237 45.5412 39.0031 41.603 45.4648C37.6994 51.8699 32.7127 58.0102 28.4298 62.6575Z" fill="white" stroke="#BEC5D0" strokeWidth="3.67453"/>
      <circle cx="24.8571" cy="25.3546" r="11.4495" fill="white" stroke="#BEC5D0" strokeWidth="3.67453"/>
    </svg>
  );
};

export default LocationIcon;
