import React from 'react';

const ContainerIcon = () => {
  return (
    <svg width="242" height="163" viewBox="0 0 242 163" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M33.3867 22.2603V134.149" stroke="#F6F6F6" strokeWidth="5.14706" strokeLinecap="round"/>
      <path d="M221.627 133.91H76.0333H5.43137C3.53628 133.91 2 132.373 2 130.478V33.0546C2 31.4946 3.05228 30.131 4.56128 29.7354L76.0333 11L222.362 43.081C223.936 43.4262 225.058 44.8209 225.058 46.4328V130.478C225.058 132.373 223.522 133.91 221.627 133.91Z" fill="white"/>
      <path d="M76.0333 11V133.91M76.0333 11L222.362 43.081C223.936 43.4262 225.058 44.8209 225.058 46.4328V130.478C225.058 132.373 223.522 133.91 221.627 133.91H76.0333M76.0333 11L4.56128 29.7354C3.05228 30.131 2 31.4946 2 33.0546V130.478C2 132.373 3.53628 133.91 5.43137 133.91H76.0333" stroke="#BEC5D0" strokeWidth="3" strokeLinecap="round"/>
      <g filter="url(#filter0_d_3042_15509)">
        <path d="M20.7959 70.6582V82.6377" stroke="#FD6120" strokeWidth="4" strokeLinecap="round"/>
      </g>
      <path d="M33.3682 23.1985L33.3682 131.242" stroke="#BEC5D0" strokeWidth="3.43137" strokeLinecap="round"/>
      <path d="M106.559 40.625L106.559 112.073" stroke="#BEC5D0" strokeWidth="3" strokeLinecap="round"/>
      <path d="M129.213 45.853L129.213 112.073" stroke="#BEC5D0" strokeWidth="3" strokeLinecap="round"/>
      <path d="M151.868 51.0811L151.868 112.074" stroke="#BEC5D0" strokeWidth="3" strokeLinecap="round"/>
      <path d="M174.522 58.0515L174.522 112.073" stroke="#BEC5D0" strokeWidth="3" strokeLinecap="round"/>
      <path d="M197.176 61.5366L197.176 110.331" stroke="#BEC5D0" strokeWidth="3" strokeLinecap="round"/>
      <g filter="url(#filter1_d_3042_15509)">
        <path d="M46.1504 67.543V82.8767" stroke="#FD6120" strokeWidth="4" strokeLinecap="round"/>
      </g>
      <path d="M216.104 27.1989C216.104 24.0002 218.697 21.4072 221.896 21.4072H229.386C232.584 21.4072 235.177 24.0002 235.177 27.1989V41.6425H216.104V27.1989Z" stroke="#BEC5D0" strokeWidth="3"/>
      <path d="M225.059 66.7163C233.587 66.7163 240.5 59.803 240.5 51.2751C240.5 42.7472 233.587 35.834 225.059 35.834C216.531 35.834 209.618 42.7472 209.618 51.2751C209.618 59.803 216.531 66.7163 225.059 66.7163Z" fill="white" stroke="#BEC5D0" strokeWidth="3"/>
      <g filter="url(#filter2_d_3042_15509)">
        <path fillRule="evenodd" clipRule="evenodd" d="M225.129 52.0797C226.386 51.5708 227.274 50.3378 227.274 48.8974C227.274 47.0023 225.738 45.4661 223.843 45.4661C221.947 45.4661 220.411 47.0023 220.411 48.8974C220.411 50.3372 221.298 51.5699 222.555 52.0791V54.0443C222.555 54.755 223.131 55.3311 223.842 55.3311C224.552 55.3311 225.129 54.755 225.129 54.0443V52.0797Z" fill="#FD6120"/>
      </g>
      <defs>
        <filter id="filter0_d_3042_15509" x="13.3057" y="63.9" width="14.9804" height="26.9599" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="0.732028"/>
          <feGaussianBlur stdDeviation="2.7451"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.992157 0 0 0 0 0.380392 0 0 0 0 0.12549 0 0 0 0.65 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3042_15509"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3042_15509" result="shape"/>
        </filter>
        <filter id="filter1_d_3042_15509" x="38.6602" y="60.7848" width="14.9804" height="30.3141" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="0.732028"/>
          <feGaussianBlur stdDeviation="2.7451"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.992157 0 0 0 0 0.380392 0 0 0 0 0.12549 0 0 0 0.65 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3042_15509"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3042_15509" result="shape"/>
        </filter>
        <filter id="filter2_d_3042_15509" x="214.921" y="40.7079" width="17.8432" height="20.8454" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="0.732028"/>
          <feGaussianBlur stdDeviation="2.7451"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.992157 0 0 0 0 0.380392 0 0 0 0 0.12549 0 0 0 0.65 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3042_15509"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3042_15509" result="shape"/>
        </filter>
      </defs>
    </svg>

  );
};

export default ContainerIcon;
