import React, { useRef } from 'react';
import TopBar from '../components/top-bar';
import Muver from '../components/muver';
import BlockMinOrder from '../components/block-min-zakaz';
import UpacovkaBlock from '../components/upacovka-block';
import Footer from '../components/footer';
import MovingPackaging from '../components/headers/moving-packaging';
import BlockVideo from '../components/block-video';
import MessengerBlock from '../components/messenger-block';
import OurClients from '../components/ our-clients';
import Avtopark from '../components/avtopark';
import { useSelector } from 'react-redux';
import Loader from '../components/Loader';
import { createStyles, makeStyles } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import UpacovkaBlockMobile from '../components/upacovka-block/upacovka-block-mobile';
import { Helmet } from 'react-helmet';
import { useSize } from '../useSize';
import BreadCrumbs from '../components/bread-crumbs';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: '458px',
      marginLeft: '-30px',
      marginRight: '-30px',
      '@media (max-width: 1129px) and (min-width: 601px)': {
        height: '248px',
        marginLeft: '-15px',
        marginRight: '-15px',
      },
      '@media (max-width: 600px)': {
        boxShadow: '0px 14px 32px rgba(199, 203, 206, 0.7)',
        marginLeft: '0px',
        marginRight: '0px',
        borderRadius: '8px',
      },
    },
  }),
);

const Package = () => {
  const classes = useStyles()
  const { loading, lang } = useSelector((state) => state.reducer);
  const ref = useRef(null);
  const {clientWidth} = useSize(ref)
  const size600 = clientWidth <= 600

  return (
    loading ? (
      <Loader/>
    ) : (
    <div ref={ref}>
      {lang === 'ru' && (
        <Helmet
          title="Упаковка - Мой Перевозчик ™"
          meta={[
            {"name": "description", "content": "Упаковочные материалы для безопасного переезда. Компания Мой Перевозчик предоставляет востребованную упаковку для переездов любой сложности: коробки, пупырчатая пленка, гафрокартон и прочее."},
            {"name": "keywords", "content": "коробки для переезда одесса, пакеты для переезда одесса, подготовка к переезду одесса, переезд упаковка одесса, упаковка для переезда, мешки для переезда, боксы для переезда, набор для переезда одесса"}
          ]}
        >
          <meta property="og:image" content="https://www.mycarrier.company/wordpres/wp-content/uploads/2022/07/openg.jpg" />
        </Helmet>
      )}
      {lang === 'ua' && (
        <Helmet
          title="Упакова - Мій Перевізник ™"
          meta={[
            {"name": "description", "content": "Пакувальні матеріали для безпечного переїзду. Компанія Мій Перевізник надає упакову для переїздів будь-якої складності: коробки, плівки, гафрокартон та багато іншого."},
            {"name": "keywords", "content": "коробки для переїзду одеса, пакети для переїзду одеса, підготовка до переїзду одеса, переїзд упакова одеса, упакова для переїзду, мішки для переїзду, бокси для переїзду, набір для переїзду"}
          ]}
        >
          <meta property="og:image" content="https://www.mycarrier.company/wordpres/wp-content/uploads/2022/07/openg.jpg" />
        </Helmet>
      )}
      {lang === 'eu' && (
        <Helmet
          title="Package in Odesa - My Carrier ™"
          meta={[
            {"name": "description", "content": "Packing materials for safe moving. My Carrier provides packaging for moving of any complexity:  boxes, films, corrugated cardboard and much more."},
          ]}
        >
          <meta property="og:image" content="https://www.mycarrier.company/wordpres/wp-content/uploads/2022/07/openg.jpg" />
        </Helmet>
      )}
      <div className='container'>
        <TopBar/>
        <BreadCrumbs />
        <div className={classes.container}>
          <MovingPackaging />
        </div>
        {size600 ? <UpacovkaBlockMobile /> : <UpacovkaBlock upacovka id='package'/>}
        <Avtopark />
        <BlockMinOrder/>
        <OurClients />
        <BlockVideo/>
        <MessengerBlock/>
      </div>
      <Footer/>
    </div>
    )
  );
};

export default Package;
