import React from 'react'



export const CircleProgress = ({percentage, color}) => {
    return (
        <svg
        width="23"
        height="23"
        viewBox="0 0 23 23"
        style={{ transform: "rotate(-90deg)" }}
      >
        <circle
          cx="11.4"
          cy="11.4"
          r="10"
          fill="none"
          stroke="#BFC3C7"
          stroke-width="3.1"
        />
        <circle
          cx="11.4"
          cy="11.4"
          r="10"
          fill="none"
          stroke={color}
          stroke-width="3.1"
          pathLength={"99"}
          style={{ strokeDasharray: "100", strokeDashoffset: `calc(100 - ${Number(percentage)})` }}
        />
      
      </svg>
    )
}
