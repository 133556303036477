import React, { useRef } from 'react';
import AvtoparkItem from './avtopark-item';
import { useSelector } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core';
import OrangeArrow from '../../assets/OrangeArrow';
import { Link, useLocation } from 'react-router-dom';
import { pagePath } from '../../rout';
import { isMobile } from 'react-device-detect';
import { Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import classNames from 'classnames';
import { useSize } from '../../useSize';

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      marginTop: '49px',
      position: 'relative',
      '@media (max-width: 1129px) and (min-width: 601px)': {
        marginTop: '23px',
      },
      '@media (max-width: 600px)': {
        paddingBottom: '20px',
      },
    },
    titleWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    button: {
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.08em',
      color: '#FD6120',
      textDecoration: 'none',

      '@media (max-width: 600px)': {
        position: 'absolute',
        textAlign: 'center',
        bottom: '0',
        left: '0',
        width: '100%',
        padding: '12px 0 14px',
        border: '2px solid #FD6120',
        borderRadius: '8px',
        '& svg': {
          display: 'none',
        }
      },
    },
    wrapperPackage: {
      '@media (max-width: 600px)': {
        paddingBottom: '72px'
      },
    }
  }),
);

const Avtopark = () => {
  const classes = useStyles();
  const location = useLocation()
  const ref = useRef(null);
  const {clientWidth} = useSize(ref)
  const size600 = clientWidth < 600
  const { carPark, lang, city } = useSelector((state) => state.reducer);
  const isPackage = location.pathname.includes(pagePath.upacovka)

  return (
    <div className={classNames(classes.wrapper, {
      [classes.wrapperPackage]: isPackage
    })}
    ref={ref}>
      <div className={classes.titleWrapper}>
        <h2 className='block-title'>{carPark?.title}</h2>
        {isPackage && <Link to={`/${lang}/${city}${pagePath.gruzoperevozki}`} className={classes.button}>{ lang === 'ru' ?'Подробнее о автопарке' : lang === 'ua' ? 'Докладніше про автопарк' : 'More about the car park'} <OrangeArrow/></Link>}
      </div>
      {
        isPackage && size600 ? (
          <MobCarPark/>
        ) : (
          <div className='cards'>
            {carPark?.data && carPark?.data?.map((card, index) => <AvtoparkItem card={card} index={index} isPackage={isPackage} />)}
          </div>
        )
      }
    </div>
  );
};

export default Avtopark;


const MobCarPark = () => {
  const location = useLocation()
  const isPackage = location.pathname.includes(pagePath.upacovka)
  const { carPark } = useSelector((state) => state.reducer);
  return (
    <div>
      <Swiper
        scrollbar={{hide: false}}
        slidesPerView={1}
        modules={[Scrollbar]}
        spaceBetween={10}
        className="mySwiper-car-park"
      >
        {carPark?.data && carPark?.data?.map((card, index) => (
          <SwiperSlide>
            <AvtoparkItem card={card} index={index} isPackage={isPackage} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}
