import React from 'react';

const WidthDopIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.09863 0.901367V13.9014M13.0986 0.901367V13.9014" stroke="#4F5E69"/>
      <path d="M10.4014 7.2993L3.99337 7.2993M3.99337 7.2993L5.41737 8.45449M3.99337 7.2993L5.41737 6.04785" stroke="#4F5E69" strokeWidth="0.75"/>
      <path d="M8.1377 7.2993L10.5061 7.2993M10.5061 7.2993L9.08206 8.45449M10.5061 7.2993L9.08206 6.04785" stroke="#4F5E69" strokeWidth="0.75"/>
    </svg>
  );
};

export default React.memo(WidthDopIcon);
