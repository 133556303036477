import React from 'react';

const VolumeDopIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.89174 6.49639L13.0986 3.69639M6.89174 6.49639V13.2964M6.89174 6.49639L1.09863 3.69639M13.0986 3.69639V10.8964L6.89174 13.2964M13.0986 3.69639L6.89174 1.29639L1.09863 3.69639M6.89174 13.2964L1.09863 10.8964V3.69639" stroke="#4F5E69"/>
    </svg>
  );
};

export default React.memo(VolumeDopIcon);
