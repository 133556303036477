import React from 'react';
import './style.scss'
import { useSelector } from 'react-redux';

const DiscountsBlock = () => {
  const { minOrder } = useSelector((state) => state.reducer);
  return (
    <div className='discounts'>
      <div className='discounts-items'>
        <div className='discounts-item'>
          <div className='discounts-icon'>
            <img src={minOrder?.acf.tarificacia_img} alt=""/>
          </div>
          <div className='discounts-text-wrapper'>
            <div className='discounts-text'>{minOrder?.acf.tarificacia}</div>
            <div className='discounts-dop-text'>{minOrder?.acf.tarificacia_time}</div>
          </div>
        </div>

        <div className='discounts-item'>
          <div className='discounts-icon'>
            <img src={minOrder?.acf.scidka_img} alt=""/>
          </div>
          <div className='discounts-text-wrapper'>
            <div className='discounts-text'>{minOrder?.acf.scidka}</div>
            <div className='discounts-dop-text'>{minOrder?.acf.scidka_text}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiscountsBlock;
