import React from 'react';

const CelnometIcon = () => {
  return (
    <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.62305 8.04961C3.73835 8.04961 3.02031 8.78036 3.02031 9.67735C3.02031 10.5743 3.73835 11.3051 4.62305 11.3051C5.50776 11.3051 6.2258 10.5743 6.2258 9.67735C6.2258 8.78036 5.50773 8.04961 4.62305 8.04961ZM4.62305 10.6792C4.08051 10.6792 3.6379 10.2306 3.6379 9.67735C3.6379 9.12411 4.08054 8.67553 4.62305 8.67553C5.16556 8.67553 5.6082 9.12414 5.6082 9.67735C5.6082 10.2306 5.16556 10.6792 4.62305 10.6792Z" fill="#3D4D59" stroke="#3D4D59" strokeWidth="0.1"/>
      <path d="M13.4219 8.04961C12.5372 8.04961 11.8191 8.78036 11.8191 9.67735C11.8191 10.5743 12.5372 11.3051 13.4219 11.3051C14.3066 11.3051 15.0246 10.5743 15.0246 9.67735C15.0246 8.78036 14.3066 8.04961 13.4219 8.04961ZM13.4219 10.6792C12.8793 10.6792 12.4367 10.2306 12.4367 9.67735C12.4367 9.12411 12.8794 8.67553 13.4219 8.67553C13.9644 8.67553 14.407 9.12414 14.407 9.67735C14.407 10.2306 13.9644 10.6792 13.4219 10.6792Z" fill="#3D4D59" stroke="#3D4D59" strokeWidth="0.1"/>
      <path d="M16.5773 6.78472C16.5774 6.72514 16.5605 6.66608 16.5284 6.6156L14.1993 2.9343L14.1993 2.93429C14.1427 2.84479 14.0448 2.79033 13.9394 2.79033H11.0928C10.9213 2.79033 10.784 2.93132 10.784 3.10328V9.6772C10.784 9.84947 10.9213 9.99015 11.0928 9.99015H12.1279H12.1779V9.94015V9.41422V9.36422H12.1279H11.4015V3.41626H13.7706L15.9598 6.87649V9.36428H14.7158H14.6658V9.41428V9.94018V9.99018H14.7158H16.2685C16.44 9.99018 16.5773 9.8495 16.5773 9.67723V6.78472ZM16.5773 6.78472L16.5273 6.78469H16.5773V6.78472Z" fill="#3D4D59" stroke="#3D4D59" strokeWidth="0.1"/>
      <path d="M16.0098 6.20916H12.4367V4.4685H14.457H14.507V4.4185V3.89258V3.84258H14.457H12.1279C11.9564 3.84258 11.8191 3.98356 11.8191 4.15552V6.52214C11.8191 6.69442 11.9564 6.83509 12.1279 6.83509H16.0098H16.0598V6.78509V6.25916V6.20916H16.0098Z" fill="#3D4D59" stroke="#3D4D59" strokeWidth="0.1"/>
      <path d="M3.32911 9.99053H3.37911V9.94053V9.41461V9.36461H3.32911H1.56759V1.57589H10.784V9.36461H5.917H5.867V9.41461V9.94053V9.99053H5.917H11.0928C11.2643 9.99053 11.4016 9.84986 11.4016 9.67759V1.26295C11.4016 1.09098 11.2643 0.95 11.0928 0.95H1.25878C1.08758 0.95 0.95 1.09095 0.95 1.26295V9.67759C0.95 9.8499 1.08761 9.99053 1.25878 9.99053H3.32911Z" fill="#3D4D59" stroke="#3D4D59" strokeWidth="0.1"/>
      <path d="M2.86153 8.3623V8.3123H2.81153H1.25879H1.20879V8.3623V8.88823V8.93823H1.25879H2.81153H2.86153V8.88823V8.3623Z" fill="#3D4D59" stroke="#3D4D59" strokeWidth="0.1"/>
      <path d="M10.884 8.3623V8.3123H10.834H6.43457H6.38457V8.3623V8.88823V8.93823H6.43457H10.834H10.884V8.88823V8.3623Z" fill="#3D4D59" stroke="#3D4D59" strokeWidth="0.1"/>
      <path d="M16.3185 8.3623V8.3123H16.2685H15.2334H15.1834V8.3623V8.88823V8.93823H15.2334H16.2685H16.3185V8.88823V8.3623Z" fill="#3D4D59" stroke="#3D4D59" strokeWidth="0.1"/>
      <path d="M11.1428 2.05225V2.00225H11.0928H2.03516H1.98516V2.05225V2.57817V2.62817H2.03516H11.0928H11.1428V2.57817V2.05225Z" fill="#3D4D59" stroke="#3D4D59" strokeWidth="0.1"/>
      <path d="M4.93185 9.41455V9.36455H4.88185H4.36426H4.31426V9.41455V9.94048V9.99048H4.36426H4.88185H4.93185V9.94048V9.41455Z" fill="#3D4D59" stroke="#3D4D59" strokeWidth="0.1"/>
      <path d="M13.7307 9.41455V9.36455H13.6807H13.1631H13.1131V9.41455V9.94048V9.99048H13.1631H13.6807H13.7307V9.94048V9.41455Z" fill="#3D4D59" stroke="#3D4D59" strokeWidth="0.1"/>
    </svg>
  );
};

export default React.memo(CelnometIcon);
