import React from 'react';
import TopBar from '../components/top-bar';
import Muver from '../components/muver';
import Footer from '../components/footer';
import Movers from '../components/headers/movers';
import BlockMinOrder from '../components/block-min-zakaz';
import UpacovkaBlock from '../components/upacovka-block';
import BlockVideo from '../components/block-video';
import MessengerBlock from '../components/messenger-block';
import OurClients from '../components/ our-clients';
import { useSelector } from 'react-redux';
import Loader from '../components/Loader';
import { createStyles, makeStyles } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import BreadCrumbs from '../components/bread-crumbs';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: '458px',
      marginLeft: '-30px',
      marginRight: '-30px',

      '@media (max-width: 1129px) and (min-width: 601px)': {
        height: '248px',
        marginLeft: '-15px',
        marginRight: '-15px',
      },

      '@media (max-width: 600px)': {
        boxShadow: '0px 14px 32px rgba(199, 203, 206, 0.7)',
        marginLeft: '0px',
        marginRight: '0px',
        borderRadius: '8px',
      },
    },
  }),
);

const Moover = () => {
  const { loading, lang } = useSelector((state) => state.reducer);
  const classes = useStyles();
  return (
    loading ? (
      <Loader/>
    ) : (
    <div>
      {lang === 'ru' && (
        <Helmet
          title="Муверы в Одессе - Мой Перевозчик ™"
          meta={[
            {"name": "description", "content": "Мувер - он же грузчик, мебельщик и специалист по переездам. Выполнит разборку - сборку мебели. Упакует перевозимое. Погрузит, разгрузит и расставит все на свои места. Мувер - Ваш надежный помощник при переезде и грузоперевозке."},
            {"name": "keywords", "content": "мувер одесса, мувер одесса заказать, муверы одесса почасово, услуги муверов одесса, мувер переезд, грузчики одесса, грузчики одесса заказать, услуги грузчиков одесса, грузчик на час одесса, грузчики почасово, грузчики на переезд"}
          ]}
        >
          <meta property="og:image" content="https://www.mycarrier.company/wordpres/wp-content/uploads/2022/07/openg.jpg" />
        </Helmet>
      )}
      {lang === 'ua' && (
        <Helmet
          title="Мувери в Одесі - Мій Перевізник ™"
          meta={[
            {"name": "description", "content": "Мувер - він же вантажник, мебляр і фахівець із переїздів. Виконає розбирання - збирання меблів. Запакує.  Завантажить, розвантажить та розставить все на свої місця. Мувер - Ваш надійний помічник при переїзді та вантажоперевезенні."},
            {"name": "keywords", "content": "мувер одеса, мувер замовити одеса, мувери погодинно одеса, послуги муверів одеса, мувер переїзд одеса, вантажники одеса, вантажники замовити одеса, послуги вантажників одеса, вантажник на годину одеса, вантажники погодинно, вантажники на переїзд"}
          ]}
        >
          <meta property="og:image" content="https://www.mycarrier.company/wordpres/wp-content/uploads/2022/07/openg.jpg" />
        </Helmet>
      )}
      {lang === 'en' && (
        <Helmet
          title="Moving in Odesa is My Carrier ™"
          meta={[
            {"name": "description", "content": " Mover is a loader, furniture maker and moving specialist.  He will disassemble and assemble the furniture, pack, load, unload and put everything in its place. Mover is your reliable assistant when moving and trucking."},
          ]}
        >
          <meta property="og:image" content="https://www.mycarrier.company/wordpres/wp-content/uploads/2022/07/openg.jpg" />
        </Helmet>
      )}

      <div className='container'>
        <TopBar/>
        <BreadCrumbs />
        <div className={classes.container}>
          <Movers />
        </div>
        <Muver moover />
        <BlockMinOrder moover/>
        <UpacovkaBlock id='moover'/>
        <OurClients moover />
        <BlockVideo/>
        <MessengerBlock />
      </div>
      <Footer/>
    </div>
    )
  );
};

export default Moover;
