import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import './style.scss'
import ForbiddenIcon from '../../../assets/ForbiddenIcon';
import { useHistory } from 'react-router-dom';
import { pagePath } from '../../../rout';

const ConvenientStorage = () => {
  const history = useHistory();
  const { convenientStorage, lang, city } = useSelector((state) => state.reducer);

  const handleLink = useCallback(() => {
    history.push(`/${lang}/${city}${pagePath.gruzoperevozki}`)
  }, [])

  return (
    <div className='convenient-storage'>
      <div className='convenient-storage-wrapper'>

        <div>
          <div className='convenient-storage-title'>{convenientStorage?.udobnoe_hrahehie_title}</div>
          <div className='convenient-storage_items'>
            {convenientStorage?.hranenie.map((item, index) => (
              <div className='convenient-storage_item' key={`${index}_hranenie`}>
                <div className='convenient-storage_item-img'>
                  <img src={item?.icon} alt="Надёжное хранение"/>
                </div>
                <div className='convenient-storage_item-text'>{item?.text}</div>
              </div>
            ))}
          </div>
        </div>


        <div className='forbidden'>
          <div className='forbidden-title'>{convenientStorage?.zaprecheno} <ForbiddenIcon/></div>
          <div className='forbidden-items'>
            {convenientStorage?.zaprechenoItems.map((item, index) => (
              <div className='forbidden-item' key={`${index}_forbidden`}>
                <div className="forbidden-item-icon">
                  <img src={item?.icon} alt="Надёжное хранение"/>
                </div>
                <div className="forbidden-item-text">{item?.text}</div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className='help-transit'>
        <div className='help-transit-wrapper'>
          <div className="help-transit-bg">
          </div>
          <div className="help-transit-content">
            <div className='help-transit-title'>{convenientStorage?.help_title}</div>
            <div className="help-transit-text">{convenientStorage?.help_text}</div>
            <div className="help-transit-button" onClick={handleLink}>{convenientStorage?.help_button}</div>
          </div>
        </div>
      </div>

      <div className="convenient-storage-inform">
        {convenientStorage?.dop.map((item, index) => (
          <div className="convenient-storage-inform-item" key={`${index}_convenient`}>
            <div className="convenient-storage-inform-item-top">
              <div className="convenient-storage-inform-item-title">{item?.title}</div>
              <div className="convenient-storage-inform-item-icon">
                <img src={item?.icon} alt="Надёжное хранение"/>
              </div>
            </div>
            <div className="convenient-storage-inform-item-text">
              {item?.text}
            </div>
          </div>
        ))}
      </div>

      <div className="wishes">
        <div className="wishes-title">
          {convenientStorage?.osvobodite}
        </div>
        <div className="wishes-text">{convenientStorage?.osvobodite_text}</div>
      </div>

    </div>
  );
};

export default React.memo(ConvenientStorage);
