import React from 'react';

const LengthDopIcon = () => {
  return (
    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.598633" y="1.2041" width="13" height="11.728" rx="1.71217" stroke="#3D4D59"/>
      <path d="M10.3076 7.21861L3.78273 7.21861M3.78273 7.21861L5.23271 8.39487M3.78273 7.21861L5.23271 5.94434" stroke="#3D4D59" strokeWidth="0.75"/>
      <path d="M8.00391 7.21861L10.4155 7.21861M10.4155 7.21861L8.9655 8.39487M10.4155 7.21861L8.9655 5.94434" stroke="#3D4D59" strokeWidth="0.75"/>
    </svg>
  );
};

export default React.memo(LengthDopIcon);
