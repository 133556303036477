import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import orderPayment  from './slide/orderPayment.svg'
import orderPaymentMob  from './slide/orderPaymentMob.svg'
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      backgroundImage: `url("${orderPayment}") !important`,
      width: '100%',
      height: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'top',

      '@media (max-width: 1129px) and (min-width: 601px)': {
        backgroundSize: 'cover',
      },

      '@media (max-width: 600px)': {
        backgroundImage: `url("${orderPaymentMob}") !important`,
        backgroundPosition: 'center 102%',
        overflow: 'hidden',
        borderRadius: '8px'
      },
    },
    content: {
      margin: '0 30px',
      padding: '0 50px',

      '@media (max-width: 1129px) and (min-width: 601px)': {
        padding: '30px 16px',
      },

      '@media (max-width: 600px)': {
        margin: 0,
        padding: '24px 16px',
      }
    },
    title: {
      fontWeight: 600,
      fontSize: '56px',
      lineHeight: '67px',
      color: '#1E1E1E',
      paddingTop: '145px',

      '@media (max-width: 1129px) and (min-width: 601px)': {
        fontSize: '32px',
        lineHeight: '38px',
        paddingTop: '46px',
        maxWidth: '353px',
      },

      '@media (max-width: 600px)': {
        fontSize: '24px',
        lineHeight: '29px',
        paddingTop: 0,
        maxWidth: '100%',
      }
    },
    text: {
      // fontWeight: 500,
      fontSize: '24px',
      lineHeight: '32px',
      maxWidth: '425px',
      display: 'block',
      marginTop: '16px',

      '@media (max-width: 1129px) and (min-width: 601px)': {
        fontSize: '12px',
        lineHeight: '17px',
        maxWidth: '187px',
        marginTop: '8px',
      },

      '@media (max-width: 600px)': {
        marginTop: '8px',
        fontSize: '16px',
        lineHeight: '20px',
        maxWidth: '217px',
      }
    }
  }),
);

const OrderPayment = () => {
  const { slider } = useSelector((state) => state.reducer);
  const data = slider?.oplata?.[0]?.acf

  const classes = useStyles();
  return (
    <section className={classes.wrapper}>
      <div className={classes.content}>
        <h1 className={classes.title}>{data?.title}</h1>
        <span className={classes.text}>{data?.description}</span>
      </div>
    </section>
  );
};

export default OrderPayment;



