import React from 'react';

const WidthIcon = () => {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.646755" y="1.24563" width="10.7833" height="10.7833" rx="1.941" stroke="#3D4D59" strokeWidth="1.294"/>
      <path d="M10.7543 6.82576L1.16347 6.82576M1.16347 6.82576L3.29476 9.09026M1.16347 6.82576L3.29476 4.37256M9.37492 9.09026L10.912 6.82576L9.37492 4.37256" stroke="#3D4D59" strokeWidth="1.294"/>
    </svg>
  );
};

export default React.memo(WidthIcon);
