import React, { useRef, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import Play from '../../assets/Play';
import video from '../../assets/video-img.png'
import videoDesc from '../../assets/video-img-desc.png'
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import videoUa from './video-ua.mp4'
import videoEn from './video-en.mp4'

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      position: 'relative',
      marginTop: '64px',
      maxHeight: '555px',
      overflow: 'hidden',
      border: '7.9375px solid #F6F6F6',
      borderRadius: '38px',
      '@media (max-width: 1024px) and (min-width: 601px)': {
        border: '4.24374px solid #F6F6F6',
        borderRadius: '16.9749px',
        maxHeight: '333px',
        marginTop: '46px',
      },
      '@media (max-width: 600px)': {
        marginTop: '24px',
        borderRadius: '8px',
        border: '2px solid #F6F6F6',
        maxHeight: '200px',
      },

      '& img': {
        maxWidth: '100%'
      },

      '& > video[poster]': {
        // display: 'block',
        // maxWidth: '100%',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        '@media (max-width: 600px)': {
          objectFit: 'cover',
        },
      }
    },
    video: {
      maxWidth: '100%',
      width: '100%',
      '@media (max-width: 600px)': {
        borderRadius: '8px',
      },
    },
    button: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',

      '@media (max-width: 1024px) and (min-width: 601px)': {
        '& svg': {
          maxWidth: '95px',
        }
      },

      '@media (max-width: 600px)': {
        '& svg': {
          maxWidth: '56px',
        }
      },
    }
  }),
);

const BlockVideo = () => {
  const classes = useStyles();
  const { lang } = useSelector((state) => state.reducer);
  const refPlayer = useRef(null);
  const [active, setActive] = useState(true)

  const playVideo = () => {
    refPlayer.current.play();
    setActive(false)
  }

  const pauseVideo = () => {
    refPlayer.current.pause();
    setActive(true)
  }
  return (
    <div className={classes.container}>
        <video ref={refPlayer} onClick={pauseVideo} className={classes.video} poster={videoDesc}>
          {lang === 'ru' &&  <source src='https://pereezd.odessa.ua/video/moy-perevozchik-fullscreen.mp4'/>}
          {lang === 'ua' &&  <source src={videoUa}/>}
          {lang === 'en' &&  <source src={videoEn}/>}
        </video>
      {active && <button className={classes.button} onClick={playVideo}><Play/></button>}
    </div>
  );
};

export default BlockVideo;
