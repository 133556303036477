import React from 'react';
import TopBar from '../components/top-bar';
import Footer from '../components/footer';
import OrderPayment from '../components/headers/order-payment';
import MessengerBlock from '../components/messenger-block';
import Pay from '../components/pay';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import Loader from '../components/Loader';
import { createStyles, makeStyles } from '@material-ui/core';
import BreadCrumbs from '../components/bread-crumbs';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: '458px',
      marginLeft: '-30px',
      marginRight: '-30px',
      '@media (max-width: 1129px) and (min-width: 601px)': {
        height: '248px',
        marginLeft: '-15px',
        marginRight: '-15px',
      },
      '@media (max-width: 600px)': {
        boxShadow: '0px 14px 32px rgba(199, 203, 206, 0.7)',
        marginLeft: '0px',
        marginRight: '0px',
        borderRadius: '8px',
      },
    },
  }),
);

const Payment = () => {
  const classes = useStyles()
  const { loading, lang } = useSelector((state) => state.reducer);
  return (
    loading ? (
      <Loader/>
    ) : (
    <div>
      {lang === 'ru' && (
        <Helmet
          title="Оплата услуг в Одессе - Мой Перевозчик ™"
          meta={[
            {"name": "description", "content": "Онлайн-оплата услуг компании Мой Перевозчик в Одессе. Платить легко. Картами Visa, Mastercard и платежными сервисами Apple Pay & Google Pay."},
            {"name": "keywords", "content": "оплата мой перевозчик одесса, оплатить услуги мой перевозчик одесса, оплата картой мой перевозчик одесса, онлайн-оплата мой перевозчик"}
          ]}
        >
          <meta property="og:image" content="https://www.mycarrier.company/wordpres/wp-content/uploads/2022/07/openg.jpg" />
        </Helmet>
      )}
      {lang === 'ua' && (
        <Helmet
          title="Оплата послуг в Одесі - Мій Перевізник ™"
          meta={[
            {"name": "description", "content": "Онлайн-оплата послуг компанії Мій Перевізник в Одесі. Платити легко. Картами Visa, Mastercard та платіжними сервісами Apple Pay & Google Pay."},
            {"name": "keywords", "content": "оплата мій перевізник одеса, cплатити послуги мій перевізник одеса, оплата карткою мій перевізник одеса, онлайн-оплата мій перевізник"}
          ]}
        >
          <meta property="og:image" content="https://www.mycarrier.company/wordpres/wp-content/uploads/2022/07/openg.jpg" />
        </Helmet>
      )}
      {lang === 'en' && (
        <Helmet
          title="Payment for services in Odesa is My Carrier ™"
          meta={[
            {"name": "description", "content": "Online payment for My Carrier services in Kyiv. It is easy to pay with Visa, Mastercard, Apple Pay and Google Pay payment services."},
          ]}
        >
          <meta property="og:image" content="https://www.mycarrier.company/wordpres/wp-content/uploads/2022/07/openg.jpg" />
        </Helmet>
      )}

      <div className='container'>
        <TopBar/>
        <BreadCrumbs />
        <div className={classes.container}>
          <OrderPayment />
        </div>
        <Pay />
        <MessengerBlock/>
      </div>
      <Footer/>
    </div>
    )
  );
};

export default Payment;
