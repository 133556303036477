import React from 'react';
import TopBar from '../components/top-bar';
import Footer from '../components/footer';
import GarbageRemoval from '../components/headers/GarbageRemoval';
import Containers from '../components/export/containers';
import Muver from '../components/muver';
import SizeContainer from '../components/export/size-container';
import BlockVideo from '../components/block-video';
import MessengerBlock from '../components/messenger-block';
import { useSelector } from 'react-redux';
import Loader from '../components/Loader';
import { createStyles, makeStyles } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import BreadCrumbs from '../components/bread-crumbs';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: '458px',
      marginLeft: '-30px',
      marginRight: '-30px',
      '@media (max-width: 1129px) and (min-width: 601px)': {
        height: '248px',
        marginLeft: '-15px',
        marginRight: '-15px',
      },

      '@media (max-width: 600px)': {
        boxShadow: '0px 14px 32px rgba(199, 203, 206, 0.7)',
        marginLeft: '0px',
        marginRight: '0px',
        borderRadius: '8px',
      },
    },
  }),
);

const Export = () => {
  const classes = useStyles()
  const { loading, lang } = useSelector((state) => state.reducer);
  return (
    loading ? (
      <Loader/>
    ) : (
    <div>
      {lang === 'ru' && (
        <Helmet
          title="Вывоз мусора в Одессе - Мой Перевозчик ™"
          meta={[
            {"name": "description", "content": "Быстрый вывоз строительного и бытового мусора в Одессе. Компания Мой Перевозчик предоставляем самосвалы и съемные контейнеры любых объемов. Помощь в погрузке. Вывоз мусора производится исключительно на свалку."},
            {"name": "keywords", "content": "вывоз мусора одесса, вывоз мусора одесса услуга, вывоз строительного мусора в одессе, вывоз строймусора одесса, вывоз хлама в одессе, вывоз мебели одесса, контейнер для мусора одесса, контейнер для строительного мусора, заказать контейнер для мусора одесса, арендовать контейнер для мусора"}
          ]}
        >
          <meta property="og:image" content="https://www.mycarrier.company/wordpres/wp-content/uploads/2022/07/openg.jpg" />
        </Helmet>
      )}

      {lang === 'ua' && (
        <Helmet
          title="Вивіз сміття в Одесі - Мій Перевізник ™"
          meta={[
            {"name": "description", "content": "Швидкий вивіз будівельного та побутового сміття в Одесі. Компанія Мій Перевізник надає самоскиди та знімні контейнери будь-яких розмірів. Допомога у завантаженні. Вивезення сміття проводиться виключно на звалище."},
            {"name": "keywords", "content": "вивіз сміття одеса, вивіз сміття одеса послуга, вивіз будівельного сміття одеса, вивіз будсміття одеса, вивіз хламу одеса, вивіз меблів одеса, контейнер для сміття одеса, контейнер для будівельного сміття одеса, замовити контейнер для сміття одеса, орендувати контейнер для сміття в одесі"}
          ]}
        >
          <meta property="og:image" content="https://www.mycarrier.company/wordpres/wp-content/uploads/2022/07/openg.jpg" />
        </Helmet>
      )}

      {lang === 'en' && (
        <Helmet
          title="Trash removal in Odesa is My Carrier ™"
          meta={[
            {"name": "description", "content": "Fast removal of construction and household rubbish in Odesa. My Carrier provides dump trucks and removable containers of any size. Load assistance. Garbage is removed exclusively to the landfill."},
          ]}
        >
          <meta property="og:image" content="https://www.mycarrier.company/wordpres/wp-content/uploads/2022/07/openg.jpg" />
        </Helmet>
      )}

      <div className='container'>
        <TopBar/>
        <BreadCrumbs />
        <div className={classes.container}>
          <GarbageRemoval />
        </div>
        <Containers />
        <Muver vuvoz />
        <SizeContainer />
        <BlockVideo />
        <MessengerBlock />
      </div>
      <Footer/>
    </div>
    )
  );
};

export default Export;
