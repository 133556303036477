import React from 'react';

const DropDownArrow = () => {
  return (
    <svg width="45" height="43" viewBox="0 0 45 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_3472_10782)">
        <path d="M21.674 16.7106C22.071 16.1288 22.929 16.1288 23.326 16.7106L27.0623 22.1864C27.5152 22.8502 27.0398 23.75 26.2362 23.75H18.7638C17.9602 23.75 17.4848 22.8502 17.9377 22.1864L21.674 16.7106Z" fill="#FD6120"/>
      </g>
      <defs>
        <filter id="filter0_d_3472_10782" x="0.464788" y="0.00213742" width="44.0704" height="42.0701" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="1.02502"/>
          <feGaussianBlur stdDeviation="8.64859"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.992157 0 0 0 0 0.380392 0 0 0 0 0.12549 0 0 0 0.2 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3472_10782"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3472_10782" result="shape"/>
        </filter>
      </defs>
    </svg>

  );
};

export default DropDownArrow;
