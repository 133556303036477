import React, { useRef, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import DropDownArrow from '../../assets/DropDownArrow';
import classNames from 'classnames';
import { useSize } from '../../useSize';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      marginTop: '40px',

      '@media (max-width: 1024px) and (min-width: 601px)': {
        marginTop: '22px',
      },
    },
    size: {
      background: '#F6F6F6',
      borderRadius: '8px',
      padding: '35px 52px',

      '@media (max-width: 1024px) and (min-width: 601px)': {
        padding: '16px 30px',
      },

      '@media (max-width: 600px)': {
        padding: '14px',
      }
    },
    sizeTitle: {
      fontWeight: 600,
      fontSize: '32px',
      lineHeight: '38px',

      '@media (max-width: 1024px) and (min-width: 601px)': {
        fontSize: '18px',
        lineHeight: '22px',
      },

      '@media (max-width: 600px)': {
        fontSize: '14px',
        lineHeight: '19px',
        display: 'flex',
        justifyContent: 'space-between',
      },

      '& span': {
        '@media (max-width: 600px)': {
          maxWidth: '233px',
        },
      }
    },
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '24px',
      '@media (max-width: 1024px) and (min-width: 601px)': {
        marginTop: '12px',
      },
      '@media (max-width: 600px)': {
        flexDirection: 'column',
        alignItems: 'start',
        marginTop: '10px',
      }
    },
    sizeText: {
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '20px',
      color: '#8E8E8E',
      marginLeft: '24px',

      '@media (max-width: 1024px) and (min-width: 601px)': {
        fontSize: '8px',
        lineHeight: '11px',
        marginLeft: '14px',
      },
      '@media (max-width: 600px)': {
        fontSize: '12px',
        lineHeight: '14px',
        marginLeft: 0,
        marginTop: '13px',
      }
    },
    sizeParams: {
      background: '#FFFFFF',
      borderRadius: '4px',
      padding: '13px 16px',
      maxWidth: '521px',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',

      '@media (max-width: 1024px) and (min-width: 601px)': {
        padding: '8px 7px',
        maxWidth: '285px',
      },

      '@media (max-width: 600px)': {
        flexDirection: 'column',
        padding: '7px 10px 0px',
        maxWidth: '185px',
      }
    },
    sizeParam: {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '19px',
      color: '#8E8E8E',

      '@media (max-width: 1024px) and (min-width: 601px)': {
        fontSize: '9px',
        lineHeight: '10px',
      },
      '@media (max-width: 600px)': {
        fontSize: '12px',
        marginBottom: '8px',
      },

      '& span': {
        '&:last-child': {
          paddingLeft: '24px',

          '@media (max-width: 1024px) and (min-width: 601px)': {
            paddingLeft: '11px',
          },

          '@media (max-width: 600px)': {
            paddingLeft: '0',
          },
        }
      }
    },
    info: {
      marginTop: '36px',
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      columnGap: '20px',

      '@media (max-width: 1024px) and (min-width: 601px)': {
        marginTop: '17px',
        columnGap: '11px',
      },

      '@media (max-width: 600px)': {
        gridTemplateColumns: 'repeat(1, 1fr)',
        gridGap: '8px',
      }
    },
    infoItem: {
      background: '#F6F6F6',
      borderRadius: '6.16279px',
      padding: '18px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',

      '@media (max-width: 1024px) and (min-width: 601px)': {
        padding: '3px 10px',
      },

      '&:nth-child(2)': {
        '& svg': {
          '@media (max-width: 1024px) and (min-width: 601px)': {
            width: '35px',
            display: 'block',
            height: '35px',
          },
          '& image': {
            '@media (max-width: 1024px) and (min-width: 601px)': {
              width: '35px',
              display: 'block',
              height: '35px',
            },
          }
        },
      }
    },
    infoText: {
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '22px',
      color: '#1E1E1E',

      '@media (max-width: 1024px) and (min-width: 601px)': {
        fontSize: '10px',
        lineHeight: '12px',
      },
    },
    infoIcon: {
      '& svg': {
        '@media (max-width: 1024px) and (min-width: 601px)': {
          width: '26px',
          display: 'block',
          height: '26px',
        },
        '& image': {
          '@media (max-width: 1024px) and (min-width: 601px)': {
            width: '26px',
            display: 'block',
            height: '26px',
          },
        }
      },

    },
    dropDownIcon: {

    },
    active: {
      transform: 'rotate(180deg)',
      marginTop: '-10px',
    }
  }),
);

const SizeContainer = () => {
  const classes = useStyles();
  const [active, setActive] = useState(true)
  const { removalOfGarbage } = useSelector((state) => state.reducer);
  const ref = useRef(null);
  const {clientWidth} = useSize(ref)
  const size600 = clientWidth <= 600

  const handleActive = () => {
    if(!isMobile) return
    setActive(!active)
  }

  const vusota = removalOfGarbage?.vusota.split('  ');
  const shirina = removalOfGarbage?.shirina.split('  ');
  const dlina = removalOfGarbage?.dlina.split('  ');

  return (
    <div className={classes.container} ref={ref}>
      <div className={classes.size}>
        <div className={classes.sizeTitle} onClick={handleActive}>
          <span>{removalOfGarbage?.razmer}</span>
          {size600 && <div className={classNames(classes.dropDownIcon, {
            [classes.active]: !active
          })}><DropDownArrow /></div>}
        </div>
        {
          active && (
            <div className={classes.wrapper}>
              <div className={classes.sizeParams}>
                <div className={classes.sizeParam}>
                  <span>{vusota?.[0]}</span>
                  <span>{vusota?.[2]}</span>
                </div>
                <div className={classes.sizeParam}>
                  <span>{shirina?.[0]}</span>
                  <span>{shirina?.[2]}</span>
                </div>
                <div className={classes.sizeParam}>
                  <span>{dlina?.[0]}</span>
                  <span>{dlina?.[2]}</span>
                </div>
              </div>
              <div className={classes.sizeText}>{removalOfGarbage?.razmer_text}</div>
            </div>
          )
        }
      </div>

      <div className={classes.info}>
        {removalOfGarbage?.rec.map((item, index) => (
          <div className={classes.infoItem} key={`${index}_size`}>
            <div className={classes.infoText}>{item.text}</div>
            <div className={classes.infoIcon}>
              <svg width={item.icon.width} height={item.icon.height}>
                <image xlinkHref={item.icon.url} src={item.icon.url} width={item.icon.width} height={item.icon.height}/>
              </svg>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SizeContainer;
