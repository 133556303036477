import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { pagePath } from '../rout';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      fontWeight: '500',
      fontSize: '10px',
      lineHeight: '20px',
      color: '#DBDBDB',
      '@media (max-width: 1129px) and (min-width: 601px)': {

      },
      '@media (max-width: 600px)': {
        marginBottom: '5px',
      },
    },
  }),
);

const BreadCrumbs = () => {
  const classes = useStyles()
  const { breadCrumbs } = useSelector((state) => state.reducer);
  const location = useLocation()
  let value;
  for (const key in pagePath) {
    if (location.pathname.includes(pagePath[key])) {
      value = key
    }
  }

  return (
    <div className={classes.container}>
      {breadCrumbs?.acf[value]}
    </div>
  );
};

export default BreadCrumbs;
