import React from 'react';

const OrangeArrow = () => {
  return (
    <svg width="39" height="11" viewBox="0 0 39 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 6L37 6M37 6L33.3886 2M37 6L33.3886 10" stroke="#FD6120" strokeWidth="1.5"/>
    </svg>
  );
};

export default OrangeArrow;
