import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import LogoIcon from '../../assets/LogoIcon';
import './style.scss'
import classNames from 'classnames';
import { useHistory, useLocation } from "react-router-dom";
import { pagePath } from '../../rout';
import { langs } from '../../lang';
import { useDispatch, useSelector } from 'react-redux';
import { setCity, setIsOpenMenu, setLang, updateAll } from '../../store/action';
import Select  from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useSize } from '../../useSize';
import { conversion } from '../../helper';

const TopBar = ({incomplete}) => {
  const history = useHistory();
  const location = useLocation()
  const dispatch = useDispatch()
  const ref = useRef(null);
  const {clientWidth} = useSize(ref)
  const size1129 = clientWidth <= 1129

  const { menuIsOpen, lang, header, city } = useSelector((state) => state.reducer);
  const langAndCity = useMemo(() => location.pathname.split('/').filter((elem) => elem.length > 0), [location.pathname])

  useMemo(() => {
    dispatch(setCity(langAndCity[1]))
  }, [langAndCity])

  const pages = [
    {title: header?.acf.page_1, link: pagePath.gruzoperevozki },
    {title: header?.acf.page_2, link: pagePath.pereezd },
    {title: header?.acf.page_3, link: pagePath.hranenie },
    {title: header?.acf.page_4, link: pagePath.vuvoz },
    {title: header?.acf.page_5, link: pagePath.muver },
    {title: header?.acf.page_6, link: pagePath.upacovka },
    {title: header?.acf.page_7, link: pagePath.oplata },
    {title: header?.acf.page_8, link: pagePath.contactu, href: true }
  ];

  const activeLink = location.pathname
  
 const [isWork, setIsWork] = useState(false)
  const openingTimeStr = header?.acf.time_s;
  const closingTimeStr = header?.acf.time_do;

  useEffect(() => {
    if(!openingTimeStr || !closingTimeStr) return
    const [openingHourStr, openingMinuteStr] = openingTimeStr.split(':');
    const [closingHourStr, closingMinuteStr] = closingTimeStr.split(':');

    const today = new Date();
    const openingTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(openingHourStr, 10), parseInt(openingMinuteStr, 10), 0);
    const closingTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(closingHourStr, 10), parseInt(closingMinuteStr, 10), 0);

    const checkStoreStatus = () => {
      const currentTime = new Date();

      const isSunday = currentTime.getDay() === 0;

      if (isSunday) {
        // console.log('Магазин не работает в воскресенье');
        // Дополнительные действия, когда магазин не работает в воскресенье
        setIsWork(false)
      } else if (
        currentTime >= openingTime &&
        currentTime <= closingTime
      ) {
        // console.log('Магазин открыт');
        setIsWork(true)
      } else {
        // console.log('Магазин закрыт');
        setIsWork(false)
      }
    };

    checkStoreStatus();

    const interval = setInterval(checkStoreStatus, 1000);

    return () => clearInterval(interval);
  }, [openingTimeStr, closingTimeStr]);


  const handleOpenNavMenu = useCallback(() => {
    dispatch(setIsOpenMenu(!menuIsOpen))
  }, [dispatch, menuIsOpen]);

  const handleClickLink = useCallback((link) => {
    //TODO
    history.push(`/${lang}/${city}${link}`)
    dispatch(setIsOpenMenu(false))
  }, [history])

  const homeLink = useCallback(() => {
    history.push(`/${lang}/${city}`)
  },[])

  const handleLang = useCallback((lang) => {
    dispatch(setLang(lang))
    dispatch(setIsOpenMenu(false))
    history.push(`/${lang}/${city}${location.pathname.substr(11).length ? '/' + location.pathname.substr(11) : '/' }`)
  }, [dispatch])

  useEffect(() => {
    dispatch(setLang(location.pathname.substr(1, 2)))
    dispatch(updateAll())
  }, [dispatch, location.pathname])

  const includeLang =  langs.filter((item) => item.value.includes(lang))

  const [currentLang, setCurrentLang] = React.useState(includeLang[0]?.name);

  const handleChange = (event) => {
    const filterLang = langs.filter((item) => item.name === event.target.value)
    setCurrentLang(event.target.value);
    history.push(`/${filterLang[0].symbol + activeLink.substr(3)}`)
    dispatch(setLang(filterLang[0].symbol))
  };

  const handleDown = () => {
    if (size1129) {
      dispatch(setIsOpenMenu(false))
    }
    setTimeout(function() {
      window.scrollTo(0, document.body.scrollHeight);
    }, 500);
  }

  return (
    <div ref={ref} className={classNames('top-bar', {
      'top-bar-incomplete': incomplete
    })}>
      <div className="top-bar_logo" onClick={homeLink}><LogoIcon/></div>

      {(!size1129) && !incomplete && (<div className="top-bar-links">

        {pages.map((item, index) => {
          return (
            !item.href ? (
              <div itemProp="name" key={`${index}top-bar`} className={classNames(`top-bar-link top-bar-link_${lang}`, {
                'active_link': item.link.includes(langAndCity[2])
              })} onClick={() => handleClickLink(item.link)}>{item.title}</div>
            ): (
              <div onClick={handleDown} className={`top-bar-link top-bar-link_${lang}`}>{item.title}</div>
            )
          )
        })}
      </div>)}

      {((!size1129) || incomplete) && (
        <Select
          value={currentLang}
          onChange={handleChange}
          className='select'
          components={{ Root: 'div' }}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          defaultValue={includeLang[0]?.name}
          defaultChecked
          val
        >
          {langs.map((langItem, index) => {
            return (
              <MenuItem className={langItem.symbol === lang && 'display-none'} key={`${index}${langItem.value}`} value={langItem.name}>{langItem.name}</MenuItem>
            )
          })}
        </Select>
      )}

      <div className='top-bar__info'>
        <a href='tel:+380974651262' onClick={() => conversion('url')} className='top-bar__info-number'>+380974651262</a>
        <div className='top-bar__info-time'>
          <div className={classNames('top-bar__info-indicator-on', {
            'top-bar__info-indicator-off': !isWork
          })}/>
          {header?.acf.ejednevno} {header?.acf.time_s} {header?.acf.do} {header?.acf.time_do}
        </div>
      </div>

      {((size1129) && !incomplete) && (
        <div className='top-bar__nav-menu'>

          <div className={classNames('hamburger', {
            'is-active': menuIsOpen
          })} onClick={handleOpenNavMenu}>
            <span className="line"/>
            <span className="line"/>
            <span className="line"/>
          </div>

          {menuIsOpen && (
            <div className='top-bar__nav-menu-wrapper'>
              <div className='top-bar__nav-menu-pages'>
                {pages.map((page, index) => {
                  return (
                    !page.href ? (
                    <div itemProp="name" key={index + page.link} className={classNames('top-bar__nav-menu-page', {
                      'active-page': activeLink.includes(page.link)
                    })} onClick={() => handleClickLink(page.link)}>
                      {page.title}
                    </div> ) : (
                      <div onClick={handleDown} className='top-bar__nav-menu-page'>{page.title}</div>
                    )
                  )
                })}
              </div>

              <div className='top-bar_lang'>
                {
                  langs.map((item, index) => {
                    return (
                      <div onClick={() => handleLang(item.symbol)} key={`${index}_lang-item`} className='top-bar_lang-item'>{item.name}</div>
                    )
                  })
                }
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TopBar;
