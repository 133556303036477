import React from 'react';
import TopBar from '../components/top-bar';
import Footer from '../components/footer';
import { createStyles, makeStyles } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      textAlign: 'center',
      height: 'calc(100vh - 466px)',
      marginTop: '72px',

      '@media (max-width: 600px)': {

      }
    },
    number: {
      fontWeight: 600,
      fontSize: '164px',
      lineHeight: '197px',
      color: '#FD6120',

      '@media (max-width: 600px)': {
        fontSize: '114px',
        lineHeight: '137px',
      }
    },
    text: {
      marginTop: '7px',
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '20px',
      color: '#1E1E1E',
      '@media (max-width: 600px)': {
        fontSize: '16px',
        lineHeight: '20px',
      }
    },
    button: {
      background: '#FD6120',
      boxShadow: '0px 4px 10px rgba(253, 97, 32, 0.4)',
      borderRadius: '8px',
      padding: '12px 83px 14px',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '20px',
      margin: '32px auto',
      letterSpacing: '0.08em',
      color: '#FFFFFF',
    },
  }),
);

const PageNotFound = ({incomplete}) => {
  const history = useHistory();
  const classes = useStyles();

  const { lang, loader } = useSelector((state) => state.reducer);

  const handleLink = () => {
    history.push(`/${lang}`)
  }
  return (
    <div>
      <div className='container'>
        <TopBar incomplete/>
        <div className={classes.wrapper}>
          <div className={classes.number}>404</div>
          <div className={classes.text}>страница не найдена</div>
          <button className={classes.button} onClick={handleLink}>На главную</button>
        </div>
      </div>
      <Footer incomplete/>
    </div>
  );
};

export default PageNotFound;
