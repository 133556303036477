import React, { useCallback, useEffect } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import CryptoJS from 'crypto-js'

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      maxWidth: '535px',
      width: '100%',
      background: '#F6F6F6',
      borderRadius: '8px',
      margin: '0 auto',
      padding: '60px',
      marginTop: '50px',
      '@media (max-width: 600px)': {
        padding: '16px',
        marginTop: '48px',

        '& img': {
          maxWidth: '100%',
          margin: '0 auto',
          display: 'block',
        }
      }
    },
    cards: {},
    button: {
      background: '#FD6120',
      boxShadow: '0px 4px 10px rgba(253, 97, 32, 0.4)',
      borderRadius: '8px',
      marginTop: '40px',
      fontWeight: '700',
      fontSize: '16px',
      lineHeight: '20px',
      width: '100%',
      padding: '12px 0 14px',
      color: '#FFFFFF',
      letterSpacing: '0.08em',
      transition: '0.3s',

      '&:hover': {
        transition: '0.3s',
        boxShadow: 'none',
      },

      '@media (max-width: 600px)': {
        marginTop: '16px',
      }
    },
  }),
);

const Pay = () => {
  const classes = useStyles();
  const { payment } = useSelector((state) => state.reducer);
  const wayforpay = new window.Wayforpay();
  const pay = useCallback(() => {
    const rand = Math.random().toString(36).substring(7);
    const string = `pereezd_odessa_ua;pereezd.odessa.ua;${rand};1654666709;0;UAH;Перевозка;1;1`;
    const key = '4125464cd6636057b46045bac46cec587a3c106b'
    let hash = CryptoJS.HmacMD5(string, key);
    wayforpay.run({
        merchantAccount : "pereezd_odessa_ua",
        merchantDomainName : "pereezd.odessa.ua",
        merchantSignature : `${hash}`,
        orderReference : `${rand}`,
        orderDate : "1654666709",
        amount : "0",
        currency : "UAH",
        productName : "Перевозка",
        productPrice : "1",
        productCount : "1",
        language: "UA"
      },
      function (response) {
// on approved
      },
      function (response) {
// on declined
      },
      function (response) {
// on pending or in processing
      }
    );
  }, [])
  return (
    <div className={classes.wrapper}>
      <img src={payment?.acf.icon} alt=""/>
      <button className={classes.button} onClick={pay}>{payment?.acf.button}</button>
    </div>
  );
};

export default Pay;
