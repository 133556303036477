import React from 'react';
import TopBar from '../components/top-bar';
import MessengerBlock from '../components/messenger-block';
import Footer from '../components/footer';
import ContainerItem from '../components/hranenie/container-item';
import Location from '../components/hranenie/location';
import ConvenientStorage from '../components/hranenie/convenient-storage';
import SecureStorage from '../components/headers/secure-storage';
import { useSelector } from 'react-redux';
import Loader from '../components/Loader';
import { createStyles, makeStyles } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import BreadCrumbs from '../components/bread-crumbs';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: '458px',
      marginLeft: '-30px',
      marginRight: '-30px',
      '@media (max-width: 1129px) and (min-width: 601px)': {
        height: '248px',
        marginLeft: '-15px',
        marginRight: '-15px',
      },
      '@media (max-width: 600px)': {
        boxShadow: '0px 14px 32px rgba(199, 203, 206, 0.7)',
        marginLeft: '0px',
        marginRight: '0px',
        borderRadius: '8px',
      },
    },
  }),
);

const Hranenie = () => {
  const classes = useStyles()
  const { loading, lang } = useSelector((state) => state.reducer);
  return (
    loading ? (
      <Loader/>
    ) : (
    <div>
      {lang === 'ru' && (
        <Helmet
          title="Смарт-склад в Одессе - Мой Перевозчик ™"
          meta={[
            {"name": "description", "content": "Надежное хранение имущества в морских контейнерах на территории компании Мой Перевозчик в Одессе. Доступ 24/7. Круглосуточная охрана и освещение. Удобный подъезд для авто."},
            {"name": "keywords", "content": "хранение одесса, хранение вещей одесса, индивидуальное хранение одесса, склад одесса, смарт-склад одесса, мини склад одесса, аренда склада одесса, аренда складских помещений одесса, склад для хранения вещей одесса, кладовка одесса, аренда кладовки одесса, мой перевозчик хранение, мой перевозчик смарт склад"}
          ]}
        >
          <meta property="og:image" content="https://www.mycarrier.company/wordpres/wp-content/uploads/2022/07/openg.jpg" />
        </Helmet>
      )}
      {lang === 'ua' && (
        <Helmet
          title="Смарт-склад в Одесі - Мій Перевізник ™"
          meta={[
            {"name": "description", "content": "Надійне зберігання майна у морських контейнерах на території компанії Мій Перевізник в Одесі. Доступ 24/7. Цілодобова охорона та освітлення. Зручний під'їзд для авто."},
            {"name": "keywords", "content": "зберігання одеса, зберігання речей одеса, індивідуальне зберігання одеса, склад одеса, смарт-склад одеса, міні склад одеса, оренда складу одеса, оренда складських приміщень одеса, склад для зберігання речей одеса, комора одеса, оренда комори одеса, мій перевізник зберігання одеса, мій перевізник смарт склад"}
          ]}
        >
          <meta property="og:image" content="https://www.mycarrier.company/wordpres/wp-content/uploads/2022/07/openg.jpg" />
        </Helmet>
      )}

      {lang === 'en' && (
        <Helmet
          title="Smart composition in Odesa is My Carrier ™"
          meta={[
            {"name": "description", "content": "Reliable storage of property in sea containers on the territory of the My Carrier company in Odesa. 24/7 access. Round-the-clock security and lighting. Convenient car entrance."},
          ]}
        >
          <meta property="og:image" content="https://www.mycarrier.company/wordpres/wp-content/uploads/2022/07/openg.jpg" />
        </Helmet>
      )}

      <div className='container'>
        <TopBar/>
        <BreadCrumbs />
        <div className={classes.container}>
          <SecureStorage />
        </div>
        <ContainerItem/>
        <Location/>
        <ConvenientStorage/>
        <MessengerBlock/>
      </div>
      <Footer/>
    </div>
    )
  );
};

export default Hranenie;
