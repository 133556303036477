import React from 'react';

const TentIcon = () => {
  return (
    <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.65137 7.9644C3.76667 7.9644 3.04863 8.69516 3.04863 9.59215C3.04863 10.4891 3.76667 11.2199 4.65137 11.2199C5.53608 11.2199 6.25412 10.4891 6.25412 9.59215C6.25412 8.69516 5.53605 7.9644 4.65137 7.9644ZM4.65137 10.594C4.10883 10.594 3.66622 10.1454 3.66622 9.59215C3.66622 9.03891 4.10886 8.59033 4.65137 8.59033C5.19389 8.59033 5.63653 9.03894 5.63653 9.59215C5.63653 10.1454 5.19389 10.594 4.65137 10.594Z" fill="#3D4D59" stroke="#3D4D59" strokeWidth="0.1"/>
      <path d="M15.5205 7.9644C14.6358 7.9644 13.9178 8.69516 13.9178 9.59215C13.9178 10.4891 14.6358 11.2199 15.5205 11.2199C16.4052 11.2199 17.1233 10.4891 17.1233 9.59215C17.1233 8.69516 16.4052 7.9644 15.5205 7.9644ZM15.5205 10.594C14.978 10.594 14.5354 10.1454 14.5354 9.59215C14.5354 9.03891 14.978 8.59033 15.5205 8.59033C16.0631 8.59033 16.5057 9.03894 16.5057 9.59215C16.5057 10.1454 16.0631 10.594 15.5205 10.594Z" fill="#3D4D59" stroke="#3D4D59" strokeWidth="0.1"/>
      <path d="M18.676 6.69952C18.676 6.63993 18.6592 6.58087 18.627 6.53039L16.298 2.8491L16.298 2.84909C16.2413 2.75959 16.1434 2.70513 16.0381 2.70513H13.1914C13.0199 2.70513 12.8826 2.84611 12.8826 3.01807V9.59199C12.8826 9.76427 13.0199 9.90494 13.1914 9.90494H14.2265H14.2765V9.85494V9.32902V9.27902H14.2265H13.5002V3.33105H15.8692L18.0584 6.79129V9.27908H16.8144H16.7644V9.32908V9.85497V9.90497H16.8144H18.3672C18.5387 9.90497 18.676 9.7643 18.676 9.59202V6.69952ZM18.676 6.69952L18.626 6.69948H18.676V6.69952Z" fill="#3D4D59" stroke="#3D4D59" strokeWidth="0.1"/>
      <path d="M14.5694 4.3833H16.5557H16.6057V4.3333V3.80737V3.75737H16.5557H14.2266C14.055 3.75737 13.9178 3.89836 13.9178 4.07032V4.08045L13.9217 4.08978L14.9179 6.44747C14.9234 6.61491 15.0586 6.74988 15.2266 6.74988H18.1084H18.1584V6.69988V6.17396V6.12396L18.1084 6.12396L15.5151 6.12399L14.5694 4.3833Z" fill="#3D4D59" stroke="#3D4D59" strokeWidth="0.1"/>
      <path d="M3.42774 9.90533H3.47774V9.85533V9.3294V9.2794H3.42774H1.66622V1.49069H12.8827V9.2794H6.01564H5.96564V9.3294V9.85533V9.90533H6.01564H13.1915C13.3629 9.90533 13.5002 9.76466 13.5002 9.59238V1.17774C13.5002 1.00578 13.363 0.864795 13.1915 0.864795H1.35741C1.18621 0.864795 1.04863 1.00574 1.04863 1.17774V9.59238C1.04863 9.76469 1.18624 9.90533 1.35741 9.90533H3.42774Z" fill="#3D4D59" stroke="#3D4D59" strokeWidth="0.1"/>
      <path d="M18.4172 8.2771V8.2271H18.3672H17.332H17.282V8.2771V8.80302V8.85302H17.332H18.3672H18.4172V8.80302V8.2771Z" fill="#3D4D59" stroke="#3D4D59" strokeWidth="0.1"/>
      <path d="M4.96017 9.32935V9.27935H4.91017H4.39258H4.34258V9.32935V9.85527V9.90527H4.39258H4.91017H4.96017V9.85527V9.32935Z" fill="#3D4D59" stroke="#3D4D59" strokeWidth="0.1"/>
      <path d="M15.8293 9.32935V9.27935H15.7793H15.2617H15.2117V9.32935V9.85527V9.90527H15.2617H15.7793H15.8293V9.85527V9.32935Z" fill="#3D4D59" stroke="#3D4D59" strokeWidth="0.1"/>
    </svg>
  );
};

export default React.memo(TentIcon);
