import React from 'react';

const HeightDopIcon = () => {
  return (
    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.0986 13.165L11.7635 13.165C13.0532 13.165 14.0986 12.1196 14.0986 10.8299L14.0986 3.43352C14.0986 2.14387 13.0532 1.0984 11.7635 1.0984L10.0986 1.0984" stroke="#4F5E69"/>
      <path d="M5.09863 13.165L3.43375 13.165C2.1441 13.165 1.09863 12.1196 1.09863 10.8299L1.09863 3.43352C1.09863 2.14387 2.1441 1.0984 3.43375 1.0984L5.09863 1.0984" stroke="#4F5E69"/>
      <path d="M8.00049 4.48629L8.6625 5.26403L9.23362 4.7779L7.61388 2.875L6.10979 4.78928L6.69953 5.25265L7.25049 4.55143L7.2363 9.44836L6.71068 8.77939L6.09863 9.26029L7.61344 11.1882L9.24442 9.2721L8.6517 8.76758L8.01468 9.51596L8.00049 4.48629Z" fill="#4F5E69"/>
    </svg>
  );
};

export default React.memo(HeightDopIcon);
