import React from 'react';

import './style.scss'
import LocationIcon from '../../../assets/LocationIcon';
import { useSelector } from 'react-redux';

const Location = () => {
  const { location } = useSelector((state) => state.reducer);

  return (
    <div className='location'>
      <div className='location-icon'>
        <LocationIcon/>
      </div>

      <div className='location-info'>
        <div className='location-info-title'>{location?.acf.our_location}</div>

        <div className="location-street">
          <div className='location-street-info-item'>{location?.acf.street_1}</div>
          <div className='location-street-info-item'>{location?.acf.street_2}</div>
        </div>
      </div>
    </div>
  );
};

export default Location;
