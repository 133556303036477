import React, {Suspense} from 'react';
import TopBar from '../components/top-bar';
import Avtopark from '../components/avtopark';
import Muver from '../components/muver';
import Footer from '../components/footer';
import MessengerBlock from '../components/messenger-block';
import UpacovkaBlock from '../components/upacovka-block';
import BlockLink from '../components/block-link/block-link';
import InstaSlider from '../components/insta-slider';
import BlockMinOrder from '../components/block-min-zakaz';
import BlockVideo from '../components/block-video';
import { useSelector } from 'react-redux';
import Loader from '../components/Loader';
import { Helmet } from 'react-helmet';
import BreadCrumbs from '../components/bread-crumbs';
import opg from '../assets/openg.jpg'


const Home = () => {
  const { loading, lang } = useSelector((state) => state.reducer);

  return (
    loading ? (
      <Loader/>
      ) : (
      <div>
        {lang === 'ru' && (
          <Helmet
            title="Мой Перевозчик в Одессе - первая мувинговая компания в Украине"
            meta={[
              {"name": "description", "content": "Компания Мой Перевозчик предоставляет все виды мувинговых услуг в Одессе: грузоперевозки, переезды, хранение, вывоз мусора, услуги муверов и упаковку. Наша визия - мувинговая компания №1 в Мире."},
              {"name": "keywords", "content": "мой перевозчик одесса, мой перевозчик одесса сайт, компания мой перевозчик одесса, мувинг одесса, мувинговая компания одесса, мувинговая компания в Украине"}
            ]}
          >
            <meta property="og:image" content="https://www.mycarrier.company/wordpres/wp-content/uploads/2022/07/openg.jpg" />
          </Helmet>
        )}
        {lang === 'ua' && (
          <Helmet
            title="Мій Перевізник в Одесі - перша мувінгова компанія в Україні"
            meta={[
              {"name": "description", "content": "Компанія Мій Перевізник надає всі види мувінгових послуг в Одесі: вантажні перевезення, переїзди, зберігання, вивезення сміття, послуги муверів та упакову. Наша візія – мувінгова компанія №1 у Світі."},
              {"name": "keywords", "content": "мій перевізник одеса, мій перевізник одеса сайт, компанія мій перевізник одеса, мувінг одеса, мувінгова компанія одеса, мувінгова компанія в Україні"}
            ]}
          >
            <meta property="og:image" content="https://www.mycarrier.company/wordpres/wp-content/uploads/2022/07/openg.jpg" />
          </Helmet>
        )}

        {lang === 'en' && (
          <Helmet
            title="My carrier in Odesa is the first moving company in Ukraine"
            meta={[
              {"name": "description", "content": "My Carrier company provides all types of moving services in Odesa: freight transportations, movings, storage, garbage departures, movers services and packaging. Our vision is the number one moving company in the world."},
            ]}
          >
            <meta property="og:image" content="https://www.mycarrier.company/wordpres/wp-content/uploads/2022/07/openg.jpg" />
          </Helmet>
        )}
        <Suspense fallback={<div>Завантаження...</div>}>
        <div className='container'>
          <TopBar/>
          <BreadCrumbs />
          <InstaSlider/>
          <Avtopark/>
          <Muver/>
          <BlockMinOrder/>
          <UpacovkaBlock id='home'/>
          <BlockLink/>
          <BlockVideo/>
          <MessengerBlock/>
        </div>
        </Suspense>
        <Footer/>
      </div>
    )
  );
};

export default React.memo(Home);
