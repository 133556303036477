import {
  CONVENIENT_STORAGE,
  OPEN_MENU,
  LANG,
  GET_MOVER,
  MIN_ORDER,
  HOME_LINK,
  REMOVAL_GARBAGE,
  PAYMENT,
  STORAGE,
  LOCATION,
  CAR_PARK,
  PACKAGING, HEADER, MESSENGER, FOOTER, SLIDER, CITY, LOADING, OUR_CLIENTS, BREAD_CRUMBS
} from './types';

const initialState = {
  header: null,
  loading: false,
  todos: [],
  error: null,
  lang: 'ru',
  convenientStorage: null,
  menuIsOpen: false,
  mover: null,
  minOrder: null,
  homeLink: null,
  removalOfGarbage: null,
  payment: null,
  storage: null,
  location: null,
  carPark: null,
  packaging: null,
  messenger: null,
  footer: null,
  slider: null,
  city: null,
  ourClients: null,
  breadCrumbs: null,
};

export default function todosReducer(state = initialState, action) {
  switch (action.type) {
    case HEADER:
      return { ...state, header: action.payload };
    case OPEN_MENU:
      return { ...state, menuIsOpen: action.payload };
    case LANG:
      return {...state, lang: action.payload}
    case CONVENIENT_STORAGE:
      return {...state, convenientStorage: action.payload}
    case GET_MOVER:
      return {...state, mover: action.payload}
    case MIN_ORDER:
      return {...state, minOrder: action.payload}
    case HOME_LINK:
      return {...state, homeLink: action.payload}
    case REMOVAL_GARBAGE:
      return {...state, removalOfGarbage: action.payload}
    case PAYMENT:
      return {...state, payment: action.payload}
    case STORAGE:
      return {...state, storage: action.payload}
    case LOCATION:
      return {...state, location: action.payload}
    case CAR_PARK:
      return {...state, carPark: action.payload}
    case PACKAGING:
      return {...state, packaging: action.payload}
    case LOADING:
      return {...state, loading: action.payload}
    case MESSENGER:
      return {...state, messenger: action.payload}
    case FOOTER:
      return {...state, footer: action.payload}
    case SLIDER:
      return {...state, slider: action.payload}
    case CITY:
      return {...state, city: action.payload}
    case OUR_CLIENTS:
      return {...state, ourClients: action.payload}
    case BREAD_CRUMBS:
      return {...state, breadCrumbs: action.payload}
    default:
      return state;
  }
}
