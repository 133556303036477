import React from 'react';
import TopBar from '../components/top-bar';
import Avtopark from '../components/avtopark';
import Muver from '../components/muver';
import BlockMinOrder from '../components/block-min-zakaz';
import UpacovkaBlock from '../components/upacovka-block';
import BlockVideo from '../components/block-video';
import MessengerBlock from '../components/messenger-block';
import Footer from '../components/footer';
import ComfortableMoving from '../components/headers/comfortable-moving';
import { Helmet } from 'react-helmet';
import OurClients from '../components/ our-clients';
import { useSelector } from 'react-redux';
import Loader from '../components/Loader';
import { createStyles, makeStyles } from '@material-ui/core';
import BreadCrumbs from '../components/bread-crumbs';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: '458px',
      marginLeft: '-30px',
      marginRight: '-30px',

      '@media (max-width: 1129px) and (min-width: 601px)': {
        height: '248px',
        marginLeft: '-15px',
        marginRight: '-15px',
      },
      '@media (max-width: 600px)': {
        boxShadow: '0px 14px 32px rgba(199, 203, 206, 0.7)',
        marginLeft: '0px',
        marginRight: '0px',
        borderRadius: '8px',
      },
    },
  }),
);

const Pereezd = () => {
  const classes = useStyles()
  const { loading, lang } = useSelector((state) => state.reducer);
  return (
    loading ? (
      <Loader/>
    ) : (
    <div>
      {lang === 'ru' && (
        <Helmet
          title="Комфортный переезд в Одессе - Мой Перевозчик ™"
          meta={[
            {"name": "description", "content": "Комфортный переезд в Одессе с компанией Мой Перевозчик - упакуем, погрузим, перевезем. Квартирный, офисный, домашний и промышленный переезд - наш профиль."},
            {"name": "keywords", "content": "переезд одесса, мой переезд одесса, просто переезд одесса, комфортный переезд одесса, квартирный переезд одесса, офисный переезд одесса, дачный переезд одесса, домашний переезд одесса, промышленный переезд одесса, переезд услуга одесса, переезд мебели одесса, мой перевозчик одесса"}
          ]}
        >
          <meta property="og:image" content="https://www.mycarrier.company/wordpres/wp-content/uploads/2022/07/openg.jpg" />
        </Helmet>
      )}
      {lang === 'ua' && (
        <Helmet
          title="Комфортний переїзд в Одесі - Мій Перевізник ™"
          meta={[
            {"name": "description", "content": "Комфортний переїзд в Одесі з компанією Мій Перевізник - запакуємо, завантажимо, перевеземо. Квартирний, офісний, домашній та промисловий переїзд – наш профіль."},
            {"name": "keywords", "content": "переїзд одеса, мій переїзд одеса, просто переїзд одеса, комфортний переїзд одеса, квартирний переїзд одеса, офісний переїзд одеса, дачний переїзд одеса, домашній переїзд одеса, промисловий переїзд одеса, переїзд послуга одеса, переїзд меблів одеса, мій перевізник одеса"}
          ]}
        >
          <meta property="og:image" content="https://www.mycarrier.company/wordpres/wp-content/uploads/2022/07/openg.jpg" />
        </Helmet>
      )}
      {lang === 'en' && (
        <Helmet
          title="Comfortable movement in Odesa is My Carrier ™"
          meta={[
            {"name": "description", "content": "Comfortable moving in Odesa with a carrier - we will pack, load and transport. Apartment, office, home and industrial moving is our profile."},
          ]}
        >
          <meta property="og:image" content="https://www.mycarrier.company/wordpres/wp-content/uploads/2022/07/openg.jpg" />
        </Helmet>
      )}

      <div className='container'>
        <TopBar/>
        <BreadCrumbs />
        <div className={classes.container}>
          <ComfortableMoving />
        </div>
        <Avtopark/>
        <Muver/>
        <BlockMinOrder/>
        <UpacovkaBlock id='pereezd'/>
        <OurClients />
        <BlockVideo/>
        <MessengerBlock/>
      </div>
      <Footer/>
    </div>
    )
  );
};

export default Pereezd;
