import React from 'react';
import './style.scss'
import BigFurgonIcon from '../../assets/BigFurgonIcon';
import MoverIcon from '../../assets/MoverIcon';
import PlusIcon from '../../assets/PlusIcon';
import DiscountsBlock from '../discounts-block';
import { useSelector } from 'react-redux';

const BlockMinOrder = ({moover}) => {
  const { minOrder } = useSelector((state) => state.reducer);

  return (
    <div className='min-order-column'>
      <div className='min-order'>
        <h2 className='min-order-title'>{minOrder?.acf.title}</h2>

        {
          moover ? (
            <div className='min-order-items'>
              <div className='min-order-item item1'>
                <div className='min-order-item-img'>
                  <MoverIcon/>
                </div>
                <div className='min-order-item-text'>{minOrder?.acf.odinGruzchik}</div>
              </div>
              <div className='min-order-item item2'>
                <div className='min-order-item-img'>
                  <MoverIcon/>
                  <span className='min-order-item-img-plus'><PlusIcon/></span>
                  <MoverIcon/>
                </div>
                <div className='min-order-item-text'>{minOrder?.acf.dvaGruzchika}</div>
              </div>
              <div className='min-order-item item3'>
                <div className='min-order-item-img'>
                  <BigFurgonIcon/>
                  <span className='min-order-item-img-plus'><PlusIcon/></span>
                  <MoverIcon/>
                  <span className='min-order-item-img-delim'></span>
                  <MoverIcon/>
                </div>
                <div className='min-order-item-text'>{minOrder?.acf.gruzovik_too_mover}</div>
              </div>
            </div>
          ) : (
            <div className='min-order-items'>
              <div className='min-order-item item1'>
                <div className='min-order-item-img'>
                  <BigFurgonIcon/>
                </div>
                <div className='min-order-item-text'>{minOrder?.acf.gruzovik}</div>
              </div>
              <div className='min-order-item item2'>
                <div className='min-order-item-img'>
                  <BigFurgonIcon/>
                  <span className='min-order-item-img-plus'><PlusIcon/></span>
                  <MoverIcon/>
                </div>
                <div className='min-order-item-text'>{minOrder?.acf.gruzovik_mover}</div>
              </div>
              <div className='min-order-item item3'>
                <div className='min-order-item-img'>
                  <BigFurgonIcon/>
                  <span className='min-order-item-img-plus'><PlusIcon/></span>
                  <MoverIcon/>
                  <span className='min-order-item-img-delim'></span>
                  <MoverIcon/>
                </div>
                <div className='min-order-item-text'>{minOrder?.acf.gruzovik_too_mover}</div>
              </div>
            </div>
          )
        }

      </div>
      <div className='discount-block-wrapper'>
        <DiscountsBlock/>
      </div>
    </div>

  );
};

export default BlockMinOrder;
