import React from 'react';

const Ves = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.0184 9.77612V11.2254C14.0184 12.6547 12.8597 13.8134 11.4304 13.8134H5.39171C3.9624 13.8134 2.80371 12.6547 2.80371 11.2254V9.77612" stroke="#3D4D59" strokeWidth="1.294"/>
      <path d="M2.80371 11.8694H14.0184" stroke="#3D4D59" strokeWidth="1.294"/>
      <path d="M8.41158 2.59863V9.776M8.41158 9.776L10.748 7.6826M8.41158 9.776L6.0752 7.6826" stroke="#3D4D59" strokeWidth="1.294"/>
    </svg>
  );
};

export default Ves;
