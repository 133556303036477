import React from 'react';

const LoadСapacityIcon = () => {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.2147 7.77588V9.22515C12.2147 10.6545 11.056 11.8131 9.62671 11.8131H3.58806C2.15875 11.8131 1.00006 10.6545 1.00006 9.22515V7.77588" stroke="#3D4D59" strokeWidth="1.294"/>
      <path d="M1.00006 9.86963H12.2147" stroke="#3D4D59" strokeWidth="1.294"/>
      <path d="M6.60726 0.598633V7.776M6.60726 7.776L8.94364 5.6826M6.60726 7.776L4.27087 5.6826" stroke="#3D4D59" strokeWidth="1.294"/>
    </svg>

  );
};

export default React.memo(LoadСapacityIcon);
