import { useCallback } from "react";

const useScrollToAnchor = () => {
  return useCallback((id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
};

export default useScrollToAnchor;
