import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { BoxIcon, CarIcon, ClockIcon, LikeIcon, LoveIcon, SafeIcon, ZoomIcon } from '../assets';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      marginTop: '88px',

      '@media (max-width: 1024px) and (min-width: 601px)': {
        marginTop: '43px',
      },

      '@media (max-width: 600px)': {
        marginTop: '48px',
        marginBottom: '48px',
      },
    },
    items: {
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)',
      columnGap: '19px',
      marginTop: '40px',

      '@media (max-width: 1024px) and (min-width: 601px)': {
        marginTop: '21px',
        columnGap: '11px',
      },

      '@media (max-width: 600px)': {
        gridTemplateColumns: 'repeat(1, 1fr)',
        rowGap: '8px',
        marginTop: '24px',
      },
    },
    title: {
      fontWeight: 600,
      fontSize: '40px',
      lineHeight: '48px',

      '@media (max-width: 1024px) and (min-width: 601px)': {
        fontSize: '21px',
        lineHeight: '26px',
      },

      '@media (max-width: 600px)': {
        fontSize: '24px',
        lineHeight: '29px',
      },
    },
    item: {
      background: '#F6F6F6',
      borderRadius: '6.16279px',
      padding: '14px',
      display: 'flex',
      alignItems: 'center',
      '@media (max-width: 1024px) and (min-width: 601px)': {
        borderRadius: '3.2949px',
        padding: '4px 9px',
      },
      '@media (max-width: 600px)': {
        padding: '12px 13px',
        display: 'grid',
        gridTemplateColumns: '46px 1fr',
      },
    },
    text: {
      marginLeft: '14px',
      fontSize: '16px',
      lineHeight: '20px',
      color: '#1E1E1E',
      '@media (max-width: 1024px) and (min-width: 601px)': {
        fontSize: '8px',
        lineHeight: '11px',
        marginLeft: '8px',
      },
      '@media (max-width: 600px)': {
        maxWidth: '200px'
      },
    },
    icon: {
      '& svg': {
        maxWidth: 'inherit',

        '@media (max-width: 1024px) and (min-width: 601px)': {
          maxWidth: '20px',
          maxHeight: '30px',
        },
      },
      '@media (max-width: 600px)': {

      },
    },
    iconT: {
      '& svg': {

        '@media (max-width: 1024px) and (min-width: 601px)': {
          maxWidth: '29px',
          marginLeft: '-5px',
        },
      },
      '@media (max-width: 600px)': {
        marginLeft: '-10px',
      },
    }
  }),
);

const OurClients = ({moover}) => {
  const classes = useStyles();
  const { ourClients } = useSelector((state) => state.reducer);

  return (
    <div className={classes.container}>
      <h2 className={classes.title}>{ourClients?.acf.title}</h2>
      {!moover ? (
        <div className={classes.items}>
          <div className={classes.item}>
            <div className={classes.icon}>
             <ClockIcon/>
            </div>
            <div className={classes.text}>{ourClients?.acf.text_1}</div>
          </div>
          <div className={classes.item}>
            <div className={classes.icon}>
              <BoxIcon/>
            </div>
            <div className={classes.text}>{ourClients?.acf.text_2}</div>
          </div>
          <div className={classes.item}>
            <div className={classNames(classes.icon, classes.iconT)}>
              <CarIcon />
            </div>
            <div className={classes.text}>{ourClients?.acf.text_3}</div>
          </div>
          <div className={classes.item}>
            <div className={classes.icon}>
              <SafeIcon />
            </div>
            <div className={classes.text}>{ourClients?.acf.text_4}</div>
          </div>
        </div>
      ): (
        <div className={classes.items}>
          <div className={classes.item}>
            <div className={classes.icon}>
              <ClockIcon/>
            </div>
            <div className={classes.text}>{ourClients?.acf.text_1}</div>
          </div>
          <div className={classes.item}>
            <div className={classes.icon}>
              <ZoomIcon />
            </div>
            <div className={classes.text}>{ourClients?.acf.text_5}</div>
          </div>
          <div className={classes.item}>
            <div className={classNames(classes.icon)}>
              <LikeIcon />
            </div>
            <div className={classes.text}>{ourClients?.acf.text_6}</div>
          </div>
          <div className={classes.item}>
            <div className={classes.icon}>
              <LoveIcon />
            </div>
            <div className={classes.text}>{ourClients?.acf.text_7}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OurClients;
