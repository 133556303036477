import React, { useEffect } from 'react';
import './styles/app.scss'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { pagePath } from './rout';
import Home from './pages/home';
import Gruzoperevozki from './pages/gruzoperevozki';
import Pereezd from './pages/pereezd';
import Hranenie from './pages/hranenie';
import { useDispatch, useSelector } from 'react-redux';
import Export from './pages/export';
import Moover from './pages/moover';
import {
  getBreabCrumbs,
  getCarPark,
  getConvenientStorage, getFooter, getHeader,
  getHomeLink,
  getLocation, getMessenger,
  getMinOrder,
  getMover, getOurClients, getPackaging,
  getPayment,
  getRemovalOfGarbage, getSlider,
  getStorage
} from './store/action';
import Payment from './pages/payment';
import Package from './pages/package';
import CitySelection from './pages/city-selection';
import PageNotFound from './pages/page-not-found';
import { Redirect } from 'react-router';
import ScrollToTop from './scroll-to-top';

const path = (path) => {
  return [`/ru/odessa${path}`, `/ua/odessa${path}`, `/en/odessa${path}`, `/ru/kyiv${path}`, `/ua/kyiv${path}`, `/en/kyiv${path}`]
}

function App() {
  const dispatch = useDispatch()
  const { menuIsOpen, lang } = useSelector((state) => state.reducer);

  useEffect(() => {
    if (menuIsOpen) {
      document.body.classList.add('menu-open');
      // document.body.style.overflow = 'hidden';
    } else {
      document.body.classList.remove('menu-open');
    }
  }, [menuIsOpen])

  useEffect(() => {
    dispatch(getHeader())
    dispatch(getBreabCrumbs())
    dispatch(getMover())
    dispatch(getMinOrder())
    dispatch(getHomeLink())
    dispatch(getRemovalOfGarbage())
    dispatch(getPayment())
    dispatch(getStorage())
    dispatch(getLocation())
    dispatch(getConvenientStorage())
    dispatch(getCarPark())
    dispatch(getPackaging())
    dispatch(getMessenger())
    dispatch(getFooter())
    dispatch(getSlider())
    dispatch(getOurClients())
  }, [lang])


  return (
      <Router>
        <ScrollToTop>
        <Switch>
          <Route path={path(pagePath.oplata)} render={() => <Payment />} />
          <Route path={path(pagePath.pereezd)} render={() => <Pereezd />} />
          <Route path={path(pagePath.gruzoperevozki)} render={() => <Gruzoperevozki />} />
          <Route path={path(pagePath.hranenie)} render={() => <Hranenie />} />
          <Route path={path(pagePath.vuvoz)} render={() => <Export />} />
          <Route path={path(pagePath.muver)} render={() => <Moover />} />
          <Route path={path(pagePath.upacovka)} render={() => <Package />} />
          <Route path={path(pagePath.home)} render={() => <Home />} />
          <Route path={["/ru", "/ua", "/en"]} render={() => <CitySelection incomplete />} />
          <Route path='/'> <Redirect to="/ru" /></Route>
          <Route path='*'><PageNotFound incomplete/></Route>
        </Switch>
        </ScrollToTop>
      </Router>
  );
}

export default App;
