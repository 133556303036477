import React, { useRef } from 'react';
import ReactInstaStories  from 'react-insta-stories';
import { createStyles, makeStyles } from '@material-ui/core';
import Gruzoperevoski from '../headers/gruzoperevoski';
import ComfortableMoving from '../headers/comfortable-moving';
import SecureStorage from '../headers/secure-storage';
import GarbageRemoval from '../headers/GarbageRemoval';
import Movers from '../headers/movers';
import MovingPackaging from '../headers/moving-packaging';
import OrderPayment from '../headers/order-payment';
import { isMobile } from 'react-device-detect';
import { useSize } from '../../useSize';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      marginBottom: '46px',
      marginLeft: '-30px',
      marginRight: '-30px',
      '& div': {
        borderRadius: '8px',
        background: 'transparent !important',
        overflow: 'initial !important'
      },

      '@media (max-width: 1129px) and (min-width: 601px)': {
        marginBottom: '25px',
      },

      '@media (max-width: 600px)': {
        marginLeft: '0',
        marginRight: '0',
        marginTop: '21px',
      },

      '& > div:first-of-type': {
        background: 'transparent !important',

        '@media (max-width: 600px)': {
          boxShadow: '0px 14px 32px rgba(199, 203, 206, 0.5)',
          borderRadius: '8px',
        },
      },

      '& > div > div:first-child': {
        width: '100% !important',
        maxWidth: '500px !important',
        bottom: '2px',
        filter: 'none !important',

        '@media (max-width: 600px)': {
          top: '-26px',
          padding: '0',
        },

        '& > div': {
          background: '#E9E9E9 !important',

          '& > div': {
            background: '#FD6120 !important',
          }
        }
      }
    },
  }),
);

const InstaSlider = () => {
  const classes = useStyles();
  const ref = useRef(null);
  const {clientWidth} = useSize(ref)
  const size1129 = clientWidth <= 1129 && clientWidth >= 600

  return (
    <div className={classes.container} ref={ref}>
      <ReactInstaStories
        stories={stories}
        defaultInterval={7000}
        width={'100%'}
        height={size1129 ? 257 : 458}
        loop={true}
      />
    </div>
  );
};


const stories = [
  {
    content: (props) => <Gruzoperevoski />
  },
  {
    content: (props) => <ComfortableMoving />
  },
  {
    content: (props) => <SecureStorage />
  },
  {
    content: (props) => <GarbageRemoval />
  },
  {
    content: (props) => <Movers />
  },
  {
    content: (props) => <MovingPackaging />
  },
  {
    content: (props) => <OrderPayment />
  },
];

export default InstaSlider;
