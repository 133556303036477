export const langs = [
  {
    name: 'УКР',
    value: 'ua/',
    symbol: 'ua',
    id: 11,
  },
  {
    name: 'РУС',
    value: 'ru/',
    symbol: 'ru',
    id: 9,
  },
  {
    name: 'ENG',
    value: 'en/',
    symbol: 'en',
    id: 10,
  },
]

