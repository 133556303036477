export const pagePath = {
  home: '/',
  gruzoperevozki: '/delivery',
  pereezd: '/moving',
  hranenie: '/storage',
  vuvoz: '/removal',
  muver: '/movers',
  upacovka: '/packing',
  oplata: '/pay',
  contactu: '/contactu',
}

export const getResource = '/wp-json/wp/v2/'
