import React from 'react';

const BigFurgonIcon = () => {
  return (
    <svg width="83" height="56" viewBox="0 0 83 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M51.7088 44.9343V46.6616H53.436H75.721C78.4332 46.6616 80.6318 44.4629 80.6318 41.7507V32.8789C80.6318 31.8477 80.3072 30.8427 79.704 30.0063L70.0304 16.5932C69.1073 15.3133 67.6254 14.555 66.0474 14.555H56.6196C53.9074 14.555 51.7088 16.7536 51.7088 19.4658V44.9343Z" fill="white" stroke="#BEC5D0" strokeWidth="3.45451"/>
      <path d="M71.9226 20.2617H67.4545H62.9863C62.1072 20.2617 61.3945 20.9744 61.3945 21.8535V30.6083C61.3945 31.4874 62.1072 32.2001 62.9863 32.2001H78.9041" stroke="#BEC5D0" strokeWidth="3.45451"/>
      <circle cx="67.9068" cy="47.273" r="6.37532" fill="white" stroke="#BEC5D0" strokeWidth="3.45451"/>
      <circle cx="67.9073" cy="47.2732" r="2.16069" fill="#BEC5D0"/>
      <path d="M55.0283 46.6614H56.7556V44.9342V6.73142C56.7556 4.01925 54.5569 1.8206 51.8448 1.8206H7.27487C4.56271 1.8206 2.36406 4.01924 2.36406 6.73141V41.7506C2.36406 44.4628 4.5627 46.6614 7.27487 46.6614H55.0283Z" fill="white" stroke="#BEC5D0" strokeWidth="3.45451"/>
      <path d="M56.6197 8.323L13.6416 8.323" stroke="#BEC5D0" strokeWidth="3.45451" strokeLinecap="round"/>
      <path d="M12.0497 40.1587L2.49902 40.1587" stroke="#BEC5D0" strokeWidth="3.45451" strokeLinecap="round"/>
      <path d="M78.9057 40.1587L77.314 40.1587" stroke="#BEC5D0" strokeWidth="3.45451" strokeLinecap="round"/>
      <path d="M56.6189 40.1587L31.1504 40.1587" stroke="#BEC5D0" strokeWidth="3.45451" strokeLinecap="round"/>
      <circle cx="21.7442" cy="47.273" r="6.37532" fill="white" stroke="#BEC5D0" strokeWidth="3.45451"/>
      <circle cx="21.7432" cy="47.2732" r="2.16069" fill="#BEC5D0"/>
    </svg>
  );
};

export default React.memo(BigFurgonIcon);
