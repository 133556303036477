import React, { useEffect, useState } from 'react';
import './style.scss'
import { Tooltip } from '@material-ui/core';
import TolltipIconInfo from '../../assets/TolltipIconInfo';
import MooverIcon from '../../assets/MooverIcon';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import meshok from '../../assets/meshok.svg'

const Muver = ({vuvoz, moover}) => {
  const { mover, lang } = useSelector((state) => state.reducer);
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (isOpen) {
      const interval = setInterval(() => {
        setIsOpen(false)
      }, 7000);

      return () => {
        if (interval) clearInterval(interval);
      };
    }
  }, [isOpen])

  return (
    <div className={classNames('mover', {
      'mover-vuvoz': vuvoz,
      'mover-muver': moover,
    })}>
      <div className={classNames('mover-column', {
        ['mover-vuvoz-column']: vuvoz
      })}
           itemScope itemType="https://schema.org/Mover"
      >
        <div className='mover-column-left'>
          <div className='mover-title-container'>
            <h2 className='mover-title'>{!vuvoz ? mover?.title?.rendered : mover?.acf.gruzchik}</h2>
            {!vuvoz && (
              isMobile ? (
                  <Tooltip title={
                    lang === 'ru'? "Он же грузчик, мебельщик и специалист по переездам" : lang === 'ua' ? "Він же вантажник, мебляр і фахівець із переїздів" : "He is also a loader, furniture maker and moving specialist."
                  } arrow disableFocusListener placement='top-end' enterTouchDelay={0} open={isOpen} onTouchStartCapture={() => setIsOpen(true)}>
                    <div className='mover-title-icon'>
                      <TolltipIconInfo/>
                    </div>
                  </Tooltip>
                ) : (
                  <Tooltip title={
                    lang === 'ru'? "Он же грузчик, мебельщик и специалист по переездам" : lang === 'ua' ? "Він же вантажник, мебляр і фахівець із переїздів" : "He is also a loader, furniture maker and moving specialist."
                  } arrow disableFocusListener placement='top'>
                    <div className='mover-title-icon'>
                      <TolltipIconInfo/>
                    </div>
                  </Tooltip>
                )
            )}

          </div>
          <div className='mover-items'>
            <div className='mover-item'>
              <div className={`mover-item-text mover-item-text_${lang}`}>{mover?.acf?.minzakaz}</div>
              <div className='mover-item-value'>{mover?.acf?.minzakazcena}</div>
            </div>
            <div className='mover-item'>
              <div className={`mover-item-text mover-item-text_${lang}`}>{mover?.acf?.sledchas}</div>
              <div className='mover-item-value'>{mover?.acf?.sledchascena}</div>
            </div>
            <div className='mover-item-icon'><MooverIcon/></div>
          </div>
        </div>

        {!vuvoz ? (
          <div className='mover-column-right'>
            <div className='mover-section'>{mover?.acf.stoimost}</div>

            <div className='mover-items'>
              <div className='mover-itemv'>
                <div className='mover-itemv-img'><img src={mover?.acf?.upacovkaperevozimogokartinka.url} alt=""/></div>
                <div className='mover-itemv-text'>{mover?.acf?.upacovkaperevozimogo}</div>
              </div>
              <div className='mover-itemv'>
                <div className='mover-itemv-img'><img src={mover?.acf?.razborkamebelikartinka.url} alt=""/></div>
                <div className='mover-itemv-text'>{mover?.acf?.razborkamebeli}</div>
              </div>
              <div className='mover-itemv'>
                <div className='mover-itemv-img'><img src={mover?.acf?.spuskkartinka.url} alt=""/></div>
                <div className='mover-itemv-text'>{mover?.acf?.spusk}</div>
              </div>
              <div className='mover-itemv'>
                <div className='mover-itemv-img'><img src={mover?.acf?.pogruzkakartinka.url} alt=""/></div>
                <div className='mover-itemv-text'>{mover?.acf?.pogruzka}</div>
              </div>
            </div>
          </div>
        ) : (
          <div className='mover-column-right vuvoz-moover-section'>
            <div className='mover-section'>{mover?.acf?.meshki}</div>

            <div className='vuvoz-moover-section-wrapper'>
              <div className='mover-item-icon'><img src={meshok} alt=""/></div>
              <div className="vuvoz-moover">
                <div className='vuvoz-moover-title'>{mover?.acf?.prochnue}</div>
                <div className='vuvoz-moover-text'>{mover?.acf?.prochnue_desc}</div>
                <div className='vuvoz-moover-price'>{mover?.acf?.prochnue_price}</div>
              </div>
            </div>

          </div>
        )}
      </div>
      <div className='mover-dop-text'>{mover?.acf?.opisanie}</div>
    </div>
  );
};

export default Muver;
