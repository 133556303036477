import React, { useEffect, useState } from 'react';
import LoaderIcon from '../assets/Loader';
import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      position: 'relative',
      height: '100vh',
      width: '100%',
    },
    wrapper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    progressBar: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      color: '#FD6120',
      '& progress': {

      },

      '& progress[value]': {
        width: '100%',
        backgroundColor: 'transparent',
        appearance: 'none',
      },

      '& progress[value]::-webkit-progress-bar': {
        height: '3px',
        backgroundColor: 'transparent',
      },

      '& progress[value]::-webkit-progress-value': {
        height: '3px',
        borderRadius: '0',
        backgroundColor: '#FD6120',
        transition: 'height 1s linear, width 1s linear',
      },
    }
  }),
);

const Loader = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setValue(oldValue => {
        const newValue = oldValue + 30;

        if (newValue === 100) {
          clearInterval(interval);
        }

        return newValue;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.progressBar}>
        <progress value={value} max={100} />
      </div>
      <div className={classes.wrapper}>
        <LoaderIcon />
      </div>
    </div>
  );
};

export default Loader;
