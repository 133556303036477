import React from 'react';

const ForbiddenIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.75 12.4285L14.8929 6.03115" stroke="#FF0000" strokeWidth="2.16131" strokeLinecap="round"/>
      <circle cx="9.00011" cy="9.0316" r="7.08076" stroke="#FF0000" strokeWidth="2.16131"/>
    </svg>
  );
};

export default ForbiddenIcon;
