import React from 'react';

const Play = () => {
  return (
    <svg width="177" height="177" viewBox="0 0 177 177" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="3.96875" y="3.96875" width="168.313" height="168.313" rx="84.1563" fill="white" stroke="#F6F6F6" strokeWidth="7.9375"/>
      <g filter="url(#filter0_d_3042_15893)">
        <path fillRule="evenodd" clipRule="evenodd" d="M69.8769 65.2311C68.7108 65.4652 67.6655 66.2881 67.1276 67.3956C66.4426 68.8059 66.492 67.229 66.492 87.6967C66.492 104.578 66.5046 106.119 66.6473 106.698C67.467 110.026 70.3072 111.131 73.763 109.466C74.5294 109.096 105.686 92.1106 106.533 91.6005C107.336 91.1168 108.4 90.0449 108.747 89.3708C109.319 88.2578 109.319 87.1356 108.747 86.0226C108.4 85.3485 107.336 84.2767 106.533 83.7929C105.686 83.2828 74.5294 66.2971 73.763 65.9278C73.3809 65.7438 72.7974 65.51 72.4663 65.4085C71.7775 65.1973 70.4864 65.1088 69.8769 65.2311Z" fill="#FD6120"/>
      </g>
      <defs>
        <filter id="filter0_d_3042_15893" x="26.8037" y="33.4255" width="122.06" height="124.431" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="7.9375"/>
          <feGaussianBlur stdDeviation="19.8437"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.992157 0 0 0 0 0.380392 0 0 0 0 0.12549 0 0 0 0.5 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3042_15893"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3042_15893" result="shape"/>
        </filter>
      </defs>
    </svg>

  );
};

export default Play;
